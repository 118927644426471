/* eslint-disable import/no-anonymous-default-export */

import { SelectInputType } from '../../select/DynamicSelect';

export default {
    getPoliticalCommunityData(): SelectInputType[] {
        return [
            {
                label: 'Andau',
                value: 'Andau',
            },
            {
                label: 'Antau',
                value: 'Antau',
            },
            {
                label: 'Apetlon',
                value: 'Apetlon',
            },
            {
                label: 'Bad Sauerbrunn',
                value: 'Bad Sauerbrunn',
            },
            {
                label: 'Bad Tatzmannsdorf',
                value: 'Bad Tatzmannsdorf',
            },
            {
                label: 'Badersdorf',
                value: 'Badersdorf',
            },
            {
                label: 'Baumgarten',
                value: 'Baumgarten',
            },
            {
                label: 'Bernstein',
                value: 'Bernstein',
            },
            {
                label: 'Bildein',
                value: 'Bildein',
            },
            {
                label: 'Bocksdorf',
                value: 'Bocksdorf',
            },
            {
                label: 'Breitenbrunn am Neusiedler See',
                value: 'Breitenbrunn am Neusiedler See',
            },
            {
                label: 'Bruckneudorf',
                value: 'Bruckneudorf',
            },
            {
                label: 'Burgauberg-Neudauberg',
                value: 'Burgauberg-Neudauberg',
            },
            {
                label: 'Deutsch Jahrndorf',
                value: 'Deutsch Jahrndorf',
            },
            {
                label: 'Deutsch Kaltenbrunn',
                value: 'Deutsch Kaltenbrunn',
            },
            {
                label: 'Deutsch Schützen-Eisenberg',
                value: 'Deutsch Schützen-Eisenberg',
            },
            {
                label: 'Deutschkreutz',
                value: 'Deutschkreutz',
            },
            {
                label: 'Donnerskirchen',
                value: 'Donnerskirchen',
            },
            {
                label: 'Draßburg',
                value: 'Draßburg',
            },
            {
                label: 'Draßmarkt',
                value: 'Draßmarkt',
            },
            {
                label: 'Eberau',
                value: 'Eberau',
            },
            {
                label: 'Edelstal',
                value: 'Edelstal',
            },
            {
                label: 'Eisenstadt',
                value: 'Eisenstadt',
            },
            {
                label: 'Eltendorf',
                value: 'Eltendorf',
            },
            {
                label: 'Forchtenstein',
                value: 'Forchtenstein',
            },
            {
                label: 'Frankenau-Unterpullendorf',
                value: 'Frankenau-Unterpullendorf',
            },
            {
                label: 'Frauenkirchen',
                value: 'Frauenkirchen',
            },
            {
                label: 'Gattendorf',
                value: 'Gattendorf',
            },
            {
                label: 'Gerersdorf-Sulz',
                value: 'Gerersdorf-Sulz',
            },
            {
                label: 'Gols',
                value: 'Gols',
            },
            {
                label: 'Grafenschachen',
                value: 'Grafenschachen',
            },
            {
                label: 'Großhöflein',
                value: 'Großhöflein',
            },
            {
                label: 'Großmürbisch',
                value: 'Großmürbisch',
            },
            {
                label: 'Großpetersdorf',
                value: 'Großpetersdorf',
            },
            {
                label: 'Großwarasdorf',
                value: 'Großwarasdorf',
            },
            {
                label: 'Güssing',
                value: 'Güssing',
            },
            {
                label: 'Güttenbach',
                value: 'Güttenbach',
            },
            {
                label: 'Hackerberg',
                value: 'Hackerberg',
            },
            {
                label: 'Halbturn',
                value: 'Halbturn',
            },
            {
                label: 'Hannersdorf',
                value: 'Hannersdorf',
            },
            {
                label: 'Heiligenbrunn',
                value: 'Heiligenbrunn',
            },
            {
                label: 'Heiligenkreuz im Lafnitztal',
                value: 'Heiligenkreuz im Lafnitztal',
            },
            {
                label: 'Heugraben',
                value: 'Heugraben',
            },
            {
                label: 'Hirm',
                value: 'Hirm',
            },
            {
                label: 'Horitschon',
                value: 'Horitschon',
            },
            {
                label: 'Hornstein',
                value: 'Hornstein',
            },
            {
                label: 'Illmitz',
                value: 'Illmitz',
            },
            {
                label: 'Inzenhof',
                value: 'Inzenhof',
            },
            {
                label: 'Jabing',
                value: 'Jabing',
            },
            {
                label: 'Jennersdorf',
                value: 'Jennersdorf',
            },
            {
                label: 'Jois',
                value: 'Jois',
            },
            {
                label: 'Kaisersdorf',
                value: 'Kaisersdorf',
            },
            {
                label: 'Kemeten',
                value: 'Kemeten',
            },
            {
                label: 'Kittsee',
                value: 'Kittsee',
            },
            {
                label: 'Kleinmürbisch',
                value: 'Kleinmürbisch',
            },
            {
                label: 'Klingenbach',
                value: 'Klingenbach',
            },
            {
                label: 'Kobersdorf',
                value: 'Kobersdorf',
            },
            {
                label: 'Kohfidisch',
                value: 'Kohfidisch',
            },
            {
                label: 'Königsdorf',
                value: 'Königsdorf',
            },
            {
                label: 'Krensdorf',
                value: 'Krensdorf',
            },
            {
                label: 'Kukmirn',
                value: 'Kukmirn',
            },
            {
                label: 'Lackenbach',
                value: 'Lackenbach',
            },
            {
                label: 'Lackendorf',
                value: 'Lackendorf',
            },
            {
                label: 'Leithaprodersdorf',
                value: 'Leithaprodersdorf',
            },
            {
                label: 'Litzelsdorf',
                value: 'Litzelsdorf',
            },
            {
                label: 'Lockenhaus',
                value: 'Lockenhaus',
            },
            {
                label: 'Loipersbach im Burgenland',
                value: 'Loipersbach im Burgenland',
            },
            {
                label: 'Loipersdorf-Kitzladen',
                value: 'Loipersdorf-Kitzladen',
            },
            {
                label: 'Loretto',
                value: 'Loretto',
            },
            {
                label: 'Lutzmannsburg',
                value: 'Lutzmannsburg',
            },
            {
                label: 'Mannersdorf an der Rabnitz',
                value: 'Mannersdorf an der Rabnitz',
            },
            {
                label: 'Mariasdorf',
                value: 'Mariasdorf',
            },
            {
                label: 'Markt Allhau',
                value: 'Markt Allhau',
            },
            {
                label: 'Markt Neuhodis',
                value: 'Markt Neuhodis',
            },
            {
                label: 'Markt Sankt Martin',
                value: 'Markt Sankt Martin',
            },
            {
                label: 'Marz',
                value: 'Marz',
            },
            {
                label: 'Mattersburg',
                value: 'Mattersburg',
            },
            {
                label: 'Minihof-Liebau',
                value: 'Minihof-Liebau',
            },
            {
                label: 'Mischendorf',
                value: 'Mischendorf',
            },
            {
                label: 'Mogersdorf',
                value: 'Mogersdorf',
            },
            {
                label: 'Mönchhof',
                value: 'Mönchhof',
            },
            {
                label: 'Mörbisch am See',
                value: 'Mörbisch am See',
            },
            {
                label: 'Moschendorf',
                value: 'Moschendorf',
            },
            {
                label: 'Mühlgraben',
                value: 'Mühlgraben',
            },
            {
                label: 'Müllendorf',
                value: 'Müllendorf',
            },
            {
                label: 'Neckenmarkt',
                value: 'Neckenmarkt',
            },
            {
                label: 'Neuberg im Burgenland',
                value: 'Neuberg im Burgenland',
            },
            {
                label: 'Neudorf',
                value: 'Neudorf',
            },
            {
                label: 'Neudörfl',
                value: 'Neudörfl',
            },
            {
                label: 'Neufeld an der Leitha',
                value: 'Neufeld an der Leitha',
            },
            {
                label: 'Neuhaus am Klausenbach',
                value: 'Neuhaus am Klausenbach',
            },
            {
                label: 'Neusiedl am See',
                value: 'Neusiedl am See',
            },
            {
                label: 'Neustift an der Lafnitz',
                value: 'Neustift an der Lafnitz',
            },
            {
                label: 'Neustift bei Güssing',
                value: 'Neustift bei Güssing',
            },
            {
                label: 'Neutal',
                value: 'Neutal',
            },
            {
                label: 'Nickelsdorf',
                value: 'Nickelsdorf',
            },
            {
                label: 'Nikitsch',
                value: 'Nikitsch',
            },
            {
                label: 'Oberdorf im Burgenland',
                value: 'Oberdorf im Burgenland',
            },
            {
                label: 'Oberloisdorf',
                value: 'Oberloisdorf',
            },
            {
                label: 'Oberpullendorf',
                value: 'Oberpullendorf',
            },
            {
                label: 'Oberschützen',
                value: 'Oberschützen',
            },
            {
                label: 'Oberwart',
                value: 'Oberwart',
            },
            {
                label: 'Oggau am Neusiedler See',
                value: 'Oggau am Neusiedler See',
            },
            {
                label: 'Olbendorf',
                value: 'Olbendorf',
            },
            {
                label: 'Ollersdorf im Burgenland',
                value: 'Ollersdorf im Burgenland',
            },
            {
                label: 'Oslip',
                value: 'Oslip',
            },
            {
                label: 'Pama',
                value: 'Pama',
            },
            {
                label: 'Pamhagen',
                value: 'Pamhagen',
            },
            {
                label: 'Parndorf',
                value: 'Parndorf',
            },
            {
                label: 'Pilgersdorf',
                value: 'Pilgersdorf',
            },
            {
                label: 'Pinkafeld',
                value: 'Pinkafeld',
            },
            {
                label: 'Piringsdorf',
                value: 'Piringsdorf',
            },
            {
                label: 'Podersdorf am See',
                value: 'Podersdorf am See',
            },
            {
                label: 'Pöttelsdorf',
                value: 'Pöttelsdorf',
            },
            {
                label: 'Pöttsching',
                value: 'Pöttsching',
            },
            {
                label: 'Potzneusiedl',
                value: 'Potzneusiedl',
            },
            {
                label: 'Purbach am Neusiedler See',
                value: 'Purbach am Neusiedler See',
            },
            {
                label: 'Raiding',
                value: 'Raiding',
            },
            {
                label: 'Rauchwart',
                value: 'Rauchwart',
            },
            {
                label: 'Rechnitz',
                value: 'Rechnitz',
            },
            {
                label: 'Riedlingsdorf',
                value: 'Riedlingsdorf',
            },
            {
                label: 'Ritzing',
                value: 'Ritzing',
            },
            {
                label: 'Rohr im Burgenland',
                value: 'Rohr im Burgenland',
            },
            {
                label: 'Rohrbach bei Mattersburg',
                value: 'Rohrbach bei Mattersburg',
            },
            {
                label: 'Rotenturm an der Pinka',
                value: 'Rotenturm an der Pinka',
            },
            {
                label: 'Rudersdorf',
                value: 'Rudersdorf',
            },
            {
                label: 'Rust',
                value: 'Rust',
            },
            {
                label: 'Sankt Andrä am Zicksee',
                value: 'Sankt Andrä am Zicksee',
            },
            {
                label: 'Sankt Margarethen im Burgenland',
                value: 'Sankt Margarethen im Burgenland',
            },
            {
                label: 'Sankt Martin an der Raab',
                value: 'Sankt Martin an der Raab',
            },
            {
                label: 'Sankt Michael im Burgenland',
                value: 'Sankt Michael im Burgenland',
            },
            {
                label: 'Schachendorf',
                value: 'Schachendorf',
            },
            {
                label: 'Schandorf',
                value: 'Schandorf',
            },
            {
                label: 'Schattendorf',
                value: 'Schattendorf',
            },
            {
                label: 'Schützen am Gebirge',
                value: 'Schützen am Gebirge',
            },
            {
                label: 'Siegendorf',
                value: 'Siegendorf',
            },
            {
                label: 'Sieggraben',
                value: 'Sieggraben',
            },
            {
                label: 'Sigleß',
                value: 'Sigleß',
            },
            {
                label: 'Stadtschlaining',
                value: 'Stadtschlaining',
            },
            {
                label: 'Stegersbach',
                value: 'Stegersbach',
            },
            {
                label: 'Steinberg-Dörfl',
                value: 'Steinberg-Dörfl',
            },
            {
                label: 'Steinbrunn',
                value: 'Steinbrunn',
            },
            {
                label: 'Stinatz',
                value: 'Stinatz',
            },
            {
                label: 'Stoob',
                value: 'Stoob',
            },
            {
                label: 'Stotzing',
                value: 'Stotzing',
            },
            {
                label: 'Strem',
                value: 'Strem',
            },
            {
                label: 'Tadten',
                value: 'Tadten',
            },
            {
                label: 'Tobaj',
                value: 'Tobaj',
            },
            {
                label: 'Trausdorf an der Wulka',
                value: 'Trausdorf an der Wulka',
            },
            {
                label: 'Tschanigraben',
                value: 'Tschanigraben',
            },
            {
                label: 'Unterfrauenhaid',
                value: 'Unterfrauenhaid',
            },
            {
                label: 'Unterkohlstätten',
                value: 'Unterkohlstätten',
            },
            {
                label: 'Unterrabnitz-Schwendgraben',
                value: 'Unterrabnitz-Schwendgraben',
            },
            {
                label: 'Unterwart',
                value: 'Unterwart',
            },
            {
                label: 'Wallern im Burgenland',
                value: 'Wallern im Burgenland',
            },
            {
                label: 'Weichselbaum',
                value: 'Weichselbaum',
            },
            {
                label: 'Weiden am See',
                value: 'Weiden am See',
            },
            {
                label: 'Weiden bei Rechnitz',
                value: 'Weiden bei Rechnitz',
            },
            {
                label: 'Weingraben',
                value: 'Weingraben',
            },
            {
                label: 'Weppersdorf',
                value: 'Weppersdorf',
            },
            {
                label: 'Wiesen',
                value: 'Wiesen',
            },
            {
                label: 'Wiesfleck',
                value: 'Wiesfleck',
            },
            {
                label: 'Wimpassing an der Leitha',
                value: 'Wimpassing an der Leitha',
            },
            {
                label: 'Winden am See',
                value: 'Winden am See',
            },
            {
                label: 'Wolfau',
                value: 'Wolfau',
            },
            {
                label: 'Wörterberg',
                value: 'Wörterberg',
            },
            {
                label: 'Wulkaprodersdorf',
                value: 'Wulkaprodersdorf',
            },
            {
                label: 'Zagersdorf',
                value: 'Zagersdorf',
            },
            {
                label: 'Zemendorf-Stöttera',
                value: 'Zemendorf-Stöttera',
            },
            {
                label: 'Zillingtal',
                value: 'Zillingtal',
            },
            {
                label: 'Zurndorf',
                value: 'Zurndorf',
            },
        ];
    },
    getCatestralCommunityData(): SelectInputType[] {
        return [
            {
                label: '34082 - Unterkohlstätten',
                value: '34082',
            },
            {
                label: '32028 - Zurndorf',
                value: '32028',
            },
            {
                label: '34079 - Stuben',
                value: '34079',
            },
            {
                label: '31027 - Limbach',
                value: '31027',
            },
            {
                label: '34047 - Miedlingsdorf',
                value: '34047',
            },
            {
                label: '34044 - Mariasdorf',
                value: '34044',
            },
            {
                label: '30107 - Loipersbach',
                value: '30107',
            },
            {
                label: '33007 - Girm',
                value: '33007',
            },
            {
                label: '34065 - Riedlingsdorf',
                value: '34065',
            },
            {
                label: '32012 - Kittsee',
                value: '32012',
            },
            {
                label: '31122 - Poppendorf im Burgenland',
                value: '31122',
            },
            {
                label: '31036 - Ollersdorf',
                value: '31036',
            },
            {
                label: '31105 - Doiber',
                value: '31105',
            },
            {
                label: '34078 - Stadtschlaining',
                value: '34078',
            },
            {
                label: '33042 - Oberpetersdorf',
                value: '33042',
            },
            {
                label: '30007 - Hornstein',
                value: '30007',
            },
            {
                label: '34012 - Deutsch Schützen',
                value: '34012',
            },
            {
                label: '34016 - Edlitz',
                value: '34016',
            },
            {
                label: '31019 - Heugraben',
                value: '31019',
            },
            {
                label: '34019 - Glashütten bei Schlaining',
                value: '34019',
            },
            {
                label: '34009 - Bernstein',
                value: '34009',
            },
            {
                label: '34039 - Kohfidisch',
                value: '34039',
            },
            {
                label: '30110 - Neudörfl',
                value: '30110',
            },
            {
                label: '31120 - Neumarkt an der Raab',
                value: '31120',
            },
            {
                label: '33062 - Unterpullendorf',
                value: '33062',
            },
            {
                label: '31007 - Eisenhüttl',
                value: '31007',
            },
            {
                label: '34062 - Rechnitz',
                value: '34062',
            },
            {
                label: '31018 - Heiligenbrunn',
                value: '31018',
            },
            {
                label: '31115 - Krottendorf bei Neuhaus',
                value: '31115',
            },
            {
                label: '30002 - Donnerskirchen',
                value: '30002',
            },
            {
                label: '34011 - Burg',
                value: '34011',
            },
            {
                label: '31119 - Neuhaus am Klausenbach',
                value: '31119',
            },
            {
                label: '31037 - Punitz',
                value: '31037',
            },
            {
                label: '31020 - Inzenhof',
                value: '31020',
            },
            {
                label: '32021 - Podersdorf am See',
                value: '32021',
            },
            {
                label: '31056 - Urbersdorf',
                value: '31056',
            },
            {
                label: '30106 - Krensdorf',
                value: '30106',
            },
            {
                label: '34070 - St. Martin in der Wart',
                value: '34070',
            },
            {
                label: '31042 - St. Michael im Burgenland',
                value: '31042',
            },
            {
                label: '30115 - Sauerbrunn',
                value: '30115',
            },
            {
                label: '31049 - Strem',
                value: '31049',
            },
            {
                label: '31041 - Rohr im Burgenland',
                value: '31041',
            },
            {
                label: '30013 - Müllendorf',
                value: '30013',
            },
            {
                label: '30005 - Unterberg-Eisenstadt',
                value: '30005',
            },
            {
                label: '31133 - Zahling',
                value: '31133',
            },
            {
                label: '31009 - Gamischdorf',
                value: '31009',
            },
            {
                label: '34048 - Mischendorf',
                value: '34048',
            },
            {
                label: '30014 - Neufeld an der Leitha',
                value: '30014',
            },
            {
                label: '32020 - Parndorf',
                value: '32020',
            },
            {
                label: '31010 - Gerersdorf bei Güssing',
                value: '31010',
            },
            {
                label: '32015 - Neudorf bei Parndorf',
                value: '32015',
            },
            {
                label: '34013 - Dreihütten',
                value: '34013',
            },
            {
                label: '33061 - Unterpetersdorf',
                value: '33061',
            },
            {
                label: '33017 - Karl',
                value: '33017',
            },
            {
                label: '31126 - Rudersdorf',
                value: '31126',
            },
            {
                label: '31121 - Oberdrosen',
                value: '31121',
            },
            {
                label: '30101 - Antau',
                value: '30101',
            },
            {
                label: '30117 - Sieggraben',
                value: '30117',
            },
            {
                label: '34061 - Rauhriegel',
                value: '34061',
            },
            {
                label: '33003 - Deutschkreutz',
                value: '33003',
            },
            {
                label: '31001 - Bocksdorf',
                value: '31001',
            },
            {
                label: '30123 - Pöttsching-Rosalia',
                value: '30123',
            },
            {
                label: '31004 - Deutsch Ehrensdorf',
                value: '31004',
            },
            {
                label: '31050 - Sulz im Burgenland',
                value: '31050',
            },
            {
                label: '30017 - Purbach am Neusiedlersee',
                value: '30017',
            },
            {
                label: '34087 - Welgersdorf',
                value: '34087',
            },
            {
                label: '31043 - St. Nikolaus',
                value: '31043',
            },
            {
                label: '34001 - Allersdorf',
                value: '34001',
            },
            {
                label: '34007 - Bad Tatzmannsdorf',
                value: '34007',
            },
            {
                label: '34029 - Höll',
                value: '34029',
            },
            {
                label: '31057 - Winten',
                value: '31057',
            },
            {
                label: '31011 - Glasing',
                value: '31011',
            },
            {
                label: '30026 - Wimpassing an der Leitha',
                value: '30026',
            },
            {
                label: '30113 - Pöttsching',
                value: '30113',
            },
            {
                label: '33027 - Landsee',
                value: '33027',
            },
            {
                label: '32018 - Pama',
                value: '32018',
            },
            {
                label: '34088 - Wiesfleck',
                value: '34088',
            },
            {
                label: '33035 - Mitterpullendorf',
                value: '33035',
            },
            {
                label: '30103 - Draßburg',
                value: '30103',
            },
            {
                label: '33014 - Horitschon',
                value: '33014',
            },
            {
                label: '32008 - Gols',
                value: '32008',
            },
            {
                label: '32013 - Kaisersteinbruch',
                value: '32013',
            },
            {
                label: '34042 - Litzelsdorf',
                value: '34042',
            },
            {
                label: '33022 - Kogl',
                value: '33022',
            },
            {
                label: '33024 - Kroatisch Minihof',
                value: '33024',
            },
            {
                label: '33060 - Unterloisdorf',
                value: '33060',
            },
            {
                label: '31039 - Rehgraben',
                value: '31039',
            },
            {
                label: '32016 - Neusiedl am See',
                value: '32016',
            },
            {
                label: '31012 - Großmürbisch',
                value: '31012',
            },
            {
                label: '30023 - Steinbrunn',
                value: '30023',
            },
            {
                label: '34030 - Holzschlag',
                value: '34030',
            },
            {
                label: '33028 - Langeck',
                value: '33028',
            },
            {
                label: '34064 - Rettenbach',
                value: '34064',
            },
            {
                label: '31028 - Luising',
                value: '31028',
            },
            {
                label: '31044 - Schallendorf im Burgenland',
                value: '31044',
            },
            {
                label: '33012 - Haschendorf',
                value: '33012',
            },
            {
                label: '34052 - Neustift an der Lafnitz',
                value: '34052',
            },
            {
                label: '30021 - Schützen am Gebirge',
                value: '30021',
            },
            {
                label: '33031 - Lindgraben',
                value: '33031',
            },
            {
                label: '32014 - Mönchhof',
                value: '32014',
            },
            {
                label: '33026 - Lackendorf',
                value: '33026',
            },
            {
                label: '33011 - Hammerteich',
                value: '33011',
            },
            {
                label: '32010 - Illmitz',
                value: '32010',
            },
            {
                label: '31006 - Eberau',
                value: '31006',
            },
            {
                label: '32007 - Gattendorf',
                value: '32007',
            },
            {
                label: '34014 - Drumling',
                value: '34014',
            },
            {
                label: '33064 - Weingraben',
                value: '33064',
            },
            {
                label: '34033 - Kemeten',
                value: '34033',
            },
            {
                label: '32003 - Bruckneudorf',
                value: '32003',
            },
            {
                label: '34063 - Redlschlag',
                value: '34063',
            },
            {
                label: '31101 - Bonisdorf',
                value: '31101',
            },
            {
                label: '30116 - Schattendorf',
                value: '30116',
            },
            {
                label: '32011 - Jois',
                value: '32011',
            },
            {
                label: '32026 - Weiden am See',
                value: '32026',
            },
            {
                label: '34006 - Badersdorf',
                value: '34006',
            },
            {
                label: '31108 - Gritsch',
                value: '31108',
            },
            {
                label: '34049 - Mönchmeierhof',
                value: '34049',
            },
            {
                label: '34027 - Harmisch',
                value: '34027',
            },
            {
                label: '31131 - Welten',
                value: '31131',
            },
            {
                label: '33002 - Deutsch Gerisdorf',
                value: '33002',
            },
            {
                label: '33058 - Tschurndorf',
                value: '33058',
            },
            {
                label: '31051 - Sumetendorf',
                value: '31051',
            },
            {
                label: '34045 - Allhau Markt',
                value: '34045',
            },
            {
                label: '34056 - Oberschützen',
                value: '34056',
            },
            {
                label: '31112 - Kalch',
                value: '31112',
            },
            {
                label: '31008 - Gaas',
                value: '31008',
            },
            {
                label: '31118 - Mühlgraben',
                value: '31118',
            },
            {
                label: '34035 - Kitzladen',
                value: '34035',
            },
            {
                label: '34060 - Podler',
                value: '34060',
            },
            {
                label: '30121 - Wiesen',
                value: '30121',
            },
            {
                label: '34084 - Unterwart',
                value: '34084',
            },
            {
                label: '30124 - Loipersbach-Kogel',
                value: '30124',
            },
            {
                label: '32024 - Tadten',
                value: '32024',
            },
            {
                label: '30012 - Mörbisch am See',
                value: '30012',
            },
            {
                label: '31005 - Deutsch Tschantschendorf',
                value: '31005',
            },
            {
                label: '31024 - Krottendorf bei Güssing',
                value: '31024',
            },
            {
                label: '34024 - Großpetersdorf',
                value: '34024',
            },
            {
                label: '33010 - Großwarasdorf',
                value: '33010',
            },
            {
                label: '31130 - Weichselbaum',
                value: '31130',
            },
            {
                label: '31034 - Oberbildein',
                value: '31034',
            },
            {
                label: '30109 - Mattersburg',
                value: '30109',
            },
            {
                label: '33013 - Hochstraß',
                value: '33013',
            },
            {
                label: '34043 - Loipersdorf',
                value: '34043',
            },
            {
                label: '33056 - Stoob',
                value: '33056',
            },
            {
                label: '34003 - Althodis',
                value: '34003',
            },
            {
                label: '34089 - Willersdorf',
                value: '34089',
            },
            {
                label: '34022 - Grodnau',
                value: '34022',
            },
            {
                label: '33021 - Kobersdorf',
                value: '33021',
            },
            {
                label: '31046 - Steinfurt',
                value: '31046',
            },
            {
                label: '33018 - Kleinmutschen',
                value: '33018',
            },
            {
                label: '30104 - Forchtenau',
                value: '30104',
            },
            {
                label: '34002 - Allersgraben',
                value: '34002',
            },
            {
                label: '33001 - Bubendorf',
                value: '33001',
            },
            {
                label: '31125 - Rosendorf',
                value: '31125',
            },
            {
                label: '32023 - St. Andrä',
                value: '32023',
            },
            {
                label: '32004 - Deutsch Jahrndorf',
                value: '32004',
            },
            {
                label: '34050 - Neuhaus in der Wart',
                value: '34050',
            },
            {
                label: '31035 - Olbendorf',
                value: '31035',
            },
            {
                label: '32027 - Winden',
                value: '32027',
            },
            {
                label: '32025 - Wallern im Burgenland',
                value: '32025',
            },
            {
                label: '33005 - Draßmarkt',
                value: '33005',
            },
            {
                label: '31033 - Neustift bei Güssing',
                value: '31033',
            },
            {
                label: '33044 - Oberrabnitz',
                value: '33044',
            },
            {
                label: '31022 - Kroatisch Ehrensdorf',
                value: '31022',
            },
            {
                label: '31109 - Heiligenkreuz im Lafnitztal',
                value: '31109',
            },
            {
                label: '33052 - Schwendgraben',
                value: '33052',
            },
            {
                label: '33016 - Kalkgruben',
                value: '33016',
            },
            {
                label: '31117 - Mogersdorf',
                value: '31117',
            },
            {
                label: '34086 - Weinberg im Burgenland',
                value: '34086',
            },
            {
                label: '33048 - Rattersdorf-Liebing',
                value: '33048',
            },
            {
                label: '31127 - St. Martin an der Raab',
                value: '31127',
            },
            {
                label: '33004 - Dörfl',
                value: '33004',
            },
            {
                label: '34069 - St. Kathrein im Burgenland',
                value: '34069',
            },
            {
                label: '34080 - Sulzriegel',
                value: '34080',
            },
            {
                label: '34071 - Schachendorf',
                value: '34071',
            },
            {
                label: '30122 - Zemendorf',
                value: '30122',
            },
            {
                label: '30028 - Zagersdorf',
                value: '30028',
            },
            {
                label: '34091 - Woppendorf',
                value: '34091',
            },
            {
                label: '33040 - Nikitsch',
                value: '33040',
            },
            {
                label: '33050 - Salmannsdorf',
                value: '33050',
            },
            {
                label: '31040 - Reinersdorf',
                value: '31040',
            },
            {
                label: '31047 - Steingraben',
                value: '31047',
            },
            {
                label: '31123 - Rax',
                value: '31123',
            },
            {
                label: '30015 - Oggau',
                value: '30015',
            },
            {
                label: '31107 - Grieselstein',
                value: '31107',
            },
            {
                label: '34041 - Kroisegg',
                value: '34041',
            },
            {
                label: '31111 - Jennersdorf',
                value: '31111',
            },
            {
                label: '34073 - Schmiedrait',
                value: '34073',
            },
            {
                label: '31106 - Eltendorf',
                value: '31106',
            },
            {
                label: '34046 - Neuhodis Markt',
                value: '34046',
            },
            {
                label: '31132 - Windisch Minihof',
                value: '31132',
            },
            {
                label: '34068 - Rumpersdorf',
                value: '34068',
            },
            {
                label: '32019 - Pamhagen',
                value: '32019',
            },
            {
                label: '30001 - Breitenbrunn am Neusiedler See',
                value: '30001',
            },
            {
                label: '34076 - Siget in der Wart',
                value: '34076',
            },
            {
                label: '31003 - Deutsch Bieling',
                value: '31003',
            },
            {
                label: '30105 - Hirm',
                value: '30105',
            },
            {
                label: '30016 - Oslip',
                value: '30016',
            },
            {
                label: '33059 - Unterfrauenhaid',
                value: '33059',
            },
            {
                label: '34051 - Neumarkt im Tauchental',
                value: '34051',
            },
            {
                label: '34083 - Unterschützen',
                value: '34083',
            },
            {
                label: '31030 - Neuberg',
                value: '31030',
            },
            {
                label: '34008 - Bergwerk',
                value: '34008',
            },
            {
                label: '30003 - Eisenstadt',
                value: '30003',
            },
            {
                label: '33006 - Frankenau',
                value: '33006',
            },
            {
                label: '31114 - Krobotek',
                value: '31114',
            },
            {
                label: '30004 - Oberberg-Eisenstadt',
                value: '30004',
            },
            {
                label: '31052 - Tobaj',
                value: '31052',
            },
            {
                label: '30006 - Großhöflein',
                value: '30006',
            },
            {
                label: '31102 - Deutsch Kaltenbrunn',
                value: '31102',
            },
            {
                label: '34026 - Hannersdorf',
                value: '34026',
            },
            {
                label: '33043 - Oberpullendorf',
                value: '33043',
            },
            {
                label: '33055 - Steinberg',
                value: '33055',
            },
            {
                label: '34038 - Kleinzicken',
                value: '34038',
            },
            {
                label: '33029 - Lebenbrunn',
                value: '33029',
            },
            {
                label: '34057 - Oberwart',
                value: '34057',
            },
            {
                label: '33034 - Mannersdorf an der Rabnitz',
                value: '33034',
            },
            {
                label: '30118 - Sigleß',
                value: '30118',
            },
            {
                label: '31110 - Henndorf im Burgenland',
                value: '31110',
            },
            {
                label: '30025 - Trausdorf an der Wulka',
                value: '30025',
            },
            {
                label: '34020 - Goberling',
                value: '34020',
            },
            {
                label: '33009 - Großmutschen',
                value: '33009',
            },
            {
                label: '32022 - Potzneusiedl',
                value: '32022',
            },
            {
                label: '34066 - Rohrbach an der Teich',
                value: '34066',
            },
            {
                label: '34021 - Grafenschachen',
                value: '34021',
            },
            {
                label: '34075 - Schreibersdorf',
                value: '34075',
            },
            {
                label: '31013 - Güssing',
                value: '31013',
            },
            {
                label: '33020 - Klostermarienberg',
                value: '33020',
            },
            {
                label: '31116 - Minihof-Liebau',
                value: '31116',
            },
            {
                label: '33063 - Unterrabnitz',
                value: '33063',
            },
            {
                label: '34025 - Günseck',
                value: '34025',
            },
            {
                label: '33046 - Piringsdorf',
                value: '33046',
            },
            {
                label: '32006 - Frauenkirchen',
                value: '32006',
            },
            {
                label: '31053 - Tschanigraben',
                value: '31053',
            },
            {
                label: '33023 - Kroatisch Geresdorf',
                value: '33023',
            },
            {
                label: '33033 - Lutzmannsburg',
                value: '33033',
            },
            {
                label: '33015 - Kaisersdorf',
                value: '33015',
            },
            {
                label: '30112 - Pöttelsdorf',
                value: '30112',
            },
            {
                label: '33065 - Weppersdorf',
                value: '33065',
            },
            {
                label: '34031 - Jabing',
                value: '34031',
            },
            {
                label: '30020 - St. Margarethen',
                value: '30020',
            },
            {
                label: '31002 - Burgauberg',
                value: '31002',
            },
            {
                label: '34036 - Kleinbachselten',
                value: '34036',
            },
            {
                label: '32009 - Halbturn',
                value: '32009',
            },
            {
                label: '34015 - Dürnbach',
                value: '34015',
            },
            {
                label: '31103 - Deutsch Minihof',
                value: '31103',
            },
            {
                label: '34054 - Oberdorf',
                value: '34054',
            },
            {
                label: '30114 - Rohrbach bei Mattersburg',
                value: '30114',
            },
            {
                label: '30027 - Wulkaprodersdorf',
                value: '30027',
            },
            {
                label: '30111 - Neustift an der Rosalia',
                value: '30111',
            },
            {
                label: '31029 - Moschendorf',
                value: '31029',
            },
            {
                label: '34081 - Tauchen',
                value: '34081',
            },
            {
                label: '34037 - Kleinpetersdorf',
                value: '34037',
            },
            {
                label: '30011 - Loretto',
                value: '30011',
            },
            {
                label: '33037 - Neckenmarkt',
                value: '33037',
            },
            {
                label: '31124 - Rohrbrunn',
                value: '31124',
            },
            {
                label: '34034 - Kirchfidisch',
                value: '34034',
            },
            {
                label: '33008 - Glashütten bei Langeck',
                value: '33008',
            },
            {
                label: '31048 - Stinatz',
                value: '31048',
            },
            {
                label: '34059 - Podgoria',
                value: '34059',
            },
            {
                label: '31055 - Unterbildein',
                value: '31055',
            },
            {
                label: '34018 - Eisenzicken',
                value: '34018',
            },
            {
                label: '31129 - Wallendorf',
                value: '31129',
            },
            {
                label: '34028 - Hochart',
                value: '34028',
            },
            {
                label: '33047 - Raiding',
                value: '33047',
            },
            {
                label: '33036 - Nebersdorf',
                value: '33036',
            },
            {
                label: '30102 - Baumgarten',
                value: '30102',
            },
            {
                label: '31017 - Hasendorf im Burgenland',
                value: '31017',
            },
            {
                label: '30120 - Walbersdorf',
                value: '30120',
            },
            {
                label: '33049 - Ritzing',
                value: '33049',
            },
            {
                label: '31058 - Wörterberg',
                value: '31058',
            },
            {
                label: '31021 - Kleinmürbisch',
                value: '31021',
            },
            {
                label: '30009 - Klingenbach',
                value: '30009',
            },
            {
                label: '30024 - Stotzing',
                value: '30024',
            },
            {
                label: '32017 - Nickelsdorf',
                value: '32017',
            },
            {
                label: '32005 - Edelstal',
                value: '32005',
            },
            {
                label: '34074 - Schönherrn',
                value: '34074',
            },
            {
                label: '31031 - Neudauberg',
                value: '31031',
            },
            {
                label: '30029 - Zillingtal',
                value: '30029',
            },
            {
                label: '31026 - Kulm',
                value: '31026',
            },
            {
                label: '33019 - Kleinwarasdorf',
                value: '33019',
            },
            {
                label: '34010 - Buchschachen',
                value: '34010',
            },
            {
                label: '34032 - Jormannsdorf',
                value: '34032',
            },
            {
                label: '34023 - Großbachselten',
                value: '34023',
            },
            {
                label: '33053 - Steinbach',
                value: '33053',
            },
            {
                label: '32002 - Apetlon',
                value: '32002',
            },
            {
                label: '34092 - Zuberbach',
                value: '34092',
            },
            {
                label: '33038 - Neudorf bei Landsee',
                value: '33038',
            },
            {
                label: '33032 - Lockenhaus',
                value: '33032',
            },
            {
                label: '30018 - Rust',
                value: '30018',
            },
            {
                label: '34053 - Neustift bei Schlaining',
                value: '34053',
            },
            {
                label: '31045 - Stegersbach',
                value: '31045',
            },
            {
                label: '33045 - Pilgersdorf',
                value: '33045',
            },
            {
                label: '34072 - Schandorf',
                value: '34072',
            },
            {
                label: '33066 - Langeck-Eichwald',
                value: '33066',
            },
            {
                label: '31023 - Kroatisch Tschantschendorf',
                value: '31023',
            },
            {
                label: '30108 - Marz',
                value: '30108',
            },
            {
                label: '33054 - St. Martin',
                value: '33054',
            },
            {
                label: '34077 - Spitzzicken',
                value: '34077',
            },
            {
                label: '30008 - Kleinhöflein im Burgenland',
                value: '30008',
            },
            {
                label: '33039 - Neutal',
                value: '33039',
            },
            {
                label: '31032 - Neusiedl bei Güssing',
                value: '31032',
            },
            {
                label: '34085 - Weiden bei Rechnitz',
                value: '34085',
            },
            {
                label: '31113 - Königsdorf',
                value: '31113',
            },
            {
                label: '30010 - Leithaprodersdorf',
                value: '30010',
            },
            {
                label: '31038 - Rauchwart',
                value: '31038',
            },
            {
                label: '34040 - Kotezicken',
                value: '34040',
            },
            {
                label: '34005 - Aschau',
                value: '34005',
            },
            {
                label: '31025 - Kukmirn',
                value: '31025',
            },
            {
                label: '30019 - St. Georgen',
                value: '30019',
            },
            {
                label: '33025 - Lackenbach',
                value: '33025',
            },
            {
                label: '31015 - Hackerberg',
                value: '31015',
            },
            {
                label: '34067 - Rotenturm an der Pinka',
                value: '34067',
            },
            {
                label: '30022 - Siegendorf',
                value: '30022',
            },
            {
                label: '31014 - Güttenbach',
                value: '31014',
            },
            {
                label: '32001 - Andau',
                value: '32001',
            },
            {
                label: '34004 - Altschlaining',
                value: '34004',
            },
            {
                label: '33041 - Oberloisdorf',
                value: '33041',
            },
            {
                label: '34090 - Wolfau',
                value: '34090',
            },
            {
                label: '34055 - Oberkohlstätten',
                value: '34055',
            },
            {
                label: '31054 - Tudersdorf',
                value: '31054',
            },
            {
                label: '34058 - Pinkafeld',
                value: '34058',
            },
            {
                label: '34017 - Eisenberg an der Pinka',
                value: '34017',
            },
            {
                label: '31128 - Tauka',
                value: '31128',
            },
            {
                label: '33057 - Strebersdorf',
                value: '33057',
            },
            {
                label: '31104 - Dobersdorf',
                value: '31104',
            },
            {
                label: '30119 - Stöttera',
                value: '30119',
            },
            {
                label: '31016 - Hagensdorf im Burgenland',
                value: '31016',
            },
        ];
    },
};
