import Config from '../config/Config';
import { EMail } from '../types/types';
import PromiseConverter from './PromiseConverter';

let bearerToken: string = null;

function getAuthorizationHeader() {
    return `Bearer ${bearerToken}`;
}

function getAuthorizationToken(): Promise<string> {
    const formData = new URLSearchParams();
    formData.append('grant_type', 'password');
    formData.append('username', 'verpachtung.user@martinus.at');
    formData.append('password', '9hR+wGFKwSmcw4fMamm1YAXX50ZKIjw8YfbopPLbiGreDxoPYAMRa9yWqlo8P7rTeXaP5ceU2kxtBEsNIk+IdQ==');

    return new Promise(function (resolve, reject) {
        fetch(`${Config.getServiceURL()}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Azurelogin: 'true',
            },
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                bearerToken = data['access_token'];
                resolve(data['access_token']);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function getBearer() {
    return bearerToken;
}

function clearBearer() {
    bearerToken = null;
}

function setBearer(newBearer: string) {
    bearerToken = newBearer;
}

function sendMail(url: string, data: EMail) {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return rejecter(
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
        })
    );
}

function get(url: string, withCredentials?: boolean, noCache?: boolean, noAuthHeader?: boolean): Promise<Response> {
    const h: Headers = new Headers({ Authorization: getAuthorizationHeader() });
    if (noCache) {
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');
    }

    if (noAuthHeader) {
        h.delete('Authorization');
    }

    return rejecter(
        fetch(url, {
            method: 'GET',
            headers: h,
            credentials: withCredentials ? 'include' : undefined,
        })
    );
}

function post(url: string, data: any, contentType: string, withCredentials?: boolean, noCache?: boolean, noAuthHeader?: boolean): Promise<Response> {
    const headers = new Headers({ Authorization: getAuthorizationHeader() });

    if (contentType) headers.append('Content-Type', contentType);

    if (noCache) {
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
    }

    if (noAuthHeader) {
        headers.delete('Authorization');
    }

    return rejecter(
        fetch(url, {
            method: 'POST',
            body: data,
            headers: headers,
            credentials: withCredentials ? 'include' : undefined,
        })
    );
}

function put(url: string, data: any, contentType: string): Promise<Response> {
    const headers = new Headers({ Authorization: getAuthorizationHeader() });

    if (contentType) headers.append('Content-Type', contentType);

    return rejecter(
        fetch(url, {
            method: 'PUT',
            body: data,
            headers: headers,
        })
    );
}

function deleteJson(url: string, data: any, contentType: string): Promise<Response> {
    const headers = new Headers({ Authorization: getAuthorizationHeader() });

    if (contentType) headers.append('Content-Type', contentType);

    return rejecter(
        fetch(url, {
            method: 'DELETE',
            body: data,
            headers: headers,
        })
    );
}

function Delete(url: string): Promise<Response> {
    return fetch(url, {
        method: 'DELETE',
        headers: { Authorization: getAuthorizationHeader() },
    });
}

function rejecter(promise: Promise<Response>) {
    return promise.then((res) => {
        if (res.ok) return res;
        return Promise.reject(res);
    });
}

function getJson<T>(url: string, noCache?: boolean, withCredentials?: boolean, noAuthHeader?: boolean): Promise<T> {
    return PromiseConverter.toJson(get(url, withCredentials, noCache, noAuthHeader));
}

function postJson<T>(url: string, data: any): Promise<T> {
    return PromiseConverter.toJson(post(url, JSON.stringify(data), 'application/json'));
}

function putJson<T>(url: string, data: any): Promise<T> {
    return PromiseConverter.toJson(put(url, JSON.stringify(data), 'application/json'));
}

function postVerify(url: string, data: any): Promise<Response> {
    return post(url, JSON.stringify(data), 'application/json');
}

let BaseUrl: string = '';

export default {
    get,
    getJson,
    getAuthorizationHeader,
    getAuthorizationToken,
    clearBearer,
    getBearer,
    setBearer,
    post,
    postJson,
    put,
    putJson,
    Delete,
    deleteJson,
    postVerify,
    sendMail,
    BaseUrl,
};
