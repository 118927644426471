/* eslint-disable react-hooks/exhaustive-deps */
import MapPageCSS from './MapPage.module.css';
import { AreaTreeData, LeaseData, LeasedAreaData, PropertyData } from '../../../types/types';
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import AreaTree from '../../helper/tree/AreaTree';
import DynamicSelect, { SelectInputType } from '../../helper/select/DynamicSelect';
import InventoryService from '../../../service/InventoryService';
import CommunityData from '../../helper/utils/communityData/CommunityData';
import DataConverter from '../../helper/utils/dataconverter/DataConverter';
import MapComponent from '../../map/Map';
import AreaTable from '../../helper/table/AreaTable';
import { toast } from 'react-toastify';
import ToastMessage from '../../helper/utils/toastMessage/ToastMessage';
import MapService from '../../../service/MapService';
import EventEmitter from '../../helper/utils/event/EventEmitter';
import useStateRef from 'react-usestateref';

type MapPageProps = {
    leaseData: LeaseData[];
    setLeaseData: (newLeaseData: LeaseData[]) => void;

    tablePropertyData: PropertyData[];
    tableLeasedAreaData: LeasedAreaData[];

    selectedAreaIds: string[];
    setSelectedAreaIds: (newSelectedAreaIds: string[]) => void;

    showErrorInTable: boolean;
};

const MapPage = ({
    leaseData,
    setLeaseData,
    tablePropertyData,
    tableLeasedAreaData,
    selectedAreaIds,
    setSelectedAreaIds,
    showErrorInTable,
}: MapPageProps) => {
    const [mapInitialized, setMapInitialized] = useState<boolean>(false);

    const [catestralCommunity, setCatestralCommunity] = useState<string>();
    const [politicalCommunity, setPoliticalCommunity] = useState<string>();

    const [treePropertyData, setTreePropertyData] = useState<PropertyData[]>([]);
    const [treeLeasedAreaData, setTreeLeasedAreaData, treeLeasedAreaDataRef] = useStateRef<LeasedAreaData[]>([]);

    const [inMapSelectedId, setInMapSelectedId] = useState<string>();

    const [areaTreeData, setAreaTreeData] = useState<AreaTreeData>({
        id: 'root',
        name: 'Flächen',
        children: [],
    });

    const [allExistingProperties, setAllExisitingProperties] = useState<PropertyData[]>([]);

    const [politicalCommunityData, setPoliticalCommunityData] = useState<SelectInputType[]>([]);
    const [catestralCommunityData, setCatestralCommunityData] = useState<SelectInputType[]>([]);

    //initialize Map && showInTree
    useEffect(() => {
        MapService.initMap().then(() => setMapInitialized(true));

        const handleSelectionChanged = (selectedAssetId: string) => {
            if (!selectedAssetId) return;

            const isExisting = !!treeLeasedAreaDataRef.current.find((tlad) => tlad.id === selectedAssetId);

            if (!isExisting) {
                InventoryService.getAllRelatedAssets(selectedAssetId, 'REL_PACHTFLAECHE_LIEGENSCHAFT')
                    .then((propertyRes) => {
                        const propertyData = DataConverter.convertPropertyDataToEnglishV2(propertyRes);

                        InventoryService.getAsset(selectedAssetId)
                            .then((assetRes) => {
                                const leasedAreaData = DataConverter.convertLeasedAreaDataEnglish(
                                    propertyData.map((pd) => pd.id),
                                    [assetRes]
                                );

                                setTreePropertyData((prev) => [...prev, ...propertyData]);
                                setTreeLeasedAreaData((prev) => [...prev, ...leasedAreaData]);
                            })
                            .catch((error) => {
                                toast.error(ToastMessage.getServerErrorMessage);
                            });
                    })
                    .catch((error) => {
                        toast.error(ToastMessage.getServerErrorMessage);
                    });
            }

            setInMapSelectedId(selectedAssetId);
        };

        EventEmitter.subscribe('SELECTIONCHANGED', handleSelectionChanged);
    }, []);

    //PropertyDataBuilder
    useEffect(() => {
        const searchProperties = async () => {
            try {
                let propertyData = [];

                if (politicalCommunity || catestralCommunity) {
                    const res = await InventoryService.searchProperty(politicalCommunity, catestralCommunity);
                    propertyData = DataConverter.convertPropertyDataToEnglish(res['QueryObjects']);
                }

                propertyData.sort((a, b) => {
                    if (a.catestralCommunity === b.catestralCommunity) {
                        return a.propretyNumber.localeCompare(b.propretyNumber);
                    }
                    return a.catestralCommunity.localeCompare(b.catestralCommunity);
                });

                setTreePropertyData(propertyData);
                setTreeLeasedAreaData([]);
            } catch (error) {
                toast.error(ToastMessage.getServerErrorMessage());
            }
        };

        if (catestralCommunity) {
            MapService.zoomToCatastralCommunity(catestralCommunity);
        } else {
            if (politicalCommunity) {
                MapService.zoomToPoliticalCommunity(politicalCommunity);
            }
        }

        searchProperties();
    }, [politicalCommunity, catestralCommunity]);

    //TreeBuilder
    useEffect(() => {
        const generateAreaTree = () => {
            const noLeasedAreasMessage =
                'Keine Pachtflächen vorhanden! Bitte Gemeinde und/oder Katastralgemeinde einstellen! Wenn dennoch keine zu sehen sind, gibt es keine freigegebenen Pachtflächen in diesem Gebiet! Sie können auch eine Fläche in der Karte anklicken um diese hier anzuzeigen.';

            const rootName =
                treePropertyData.length === 0 ||
                treePropertyData.filter((tpd) => treeLeasedAreaData.filter((lad) => lad.propertyDataIds.includes(tpd.id)).length !== 0).length === 0
                    ? noLeasedAreasMessage
                    : 'Pachtflächen (KG#GstNr)';

            const newAreaTreeData: AreaTreeData = {
                id: 'root',
                name: rootName,
                children: treePropertyData
                    .filter((tpd) => treeLeasedAreaData.filter((lad) => lad.propertyDataIds.includes(tpd.id)).length !== 0)
                    .map((pd) => ({
                        id: pd.id,
                        name: pd.title,
                        children: treeLeasedAreaData
                            .filter((lad) => lad.propertyDataIds.includes(pd.id))
                            .map((lad) => ({
                                id: lad.id,
                                name: lad.leasedAreaNumber,
                                isLeasedArea: true,
                                featureId: lad.featureId,
                                bio: lad.bio,
                                area: lad.area
                            })),
                    })),
            };

            setAreaTreeData(newAreaTreeData);
        };

        generateAreaTree();
    }, [treePropertyData, treeLeasedAreaData]);

    //AreaDataBuilder
    useEffect(() => {
        const fetchLeasedAreaData = async () => {
            try {
                if (treePropertyData.length === 0) return;

                const promises = treePropertyData.map(async (pd) => {
                    const res = await InventoryService.getAllRelatedAssets(pd.id, 'REL_PACHTFLAECHE_LIEGENSCHAFT');
                    return DataConverter.convertLeasedAreaDataEnglish([pd.id], res);
                });

                let leasedAreaDataArray = await Promise.all(promises);
                leasedAreaDataArray = leasedAreaDataArray.filter((lada) => lada.length !== 0);
                const newLeasedAreaData = [].concat(...leasedAreaDataArray);

                setTreeLeasedAreaData(newLeasedAreaData);
            } catch (error) {
                toast.error(ToastMessage.getServerErrorMessage());
            }
        };

        fetchLeasedAreaData();
    }, [treePropertyData]);

    //GetAllProperties
    useEffect(() => {
        const searchProperties = async () => {
            try {
                let propertyData = [];

                const res = await InventoryService.searchProperty(null, null);
                propertyData = DataConverter.convertPropertyDataToEnglish(res['QueryObjects']);

                setAllExisitingProperties(propertyData);
            } catch (error) {
                toast.error(toast.error(ToastMessage.getServerErrorMessage()));
            }
        };

        searchProperties();
    }, []);

    useEffect(() => {
        setPoliticalCommunityData(
            CommunityData.getPoliticalCommunityData().filter((pcd) =>
                allExistingProperties.find((aep) => {
                    if (catestralCommunity) {
                        return aep.politicalCommunity === pcd.value && aep.catestralCommunity === catestralCommunity;
                    } else {
                        return aep.politicalCommunity === pcd.value;
                    }
                })
            )
        );
    }, [allExistingProperties, catestralCommunity]);

    useEffect(() => {
        setCatestralCommunityData(
            CommunityData.getCatestralCommunityData().filter((pcd) =>
                allExistingProperties.find((aep) => {
                    if (politicalCommunity) {
                        return aep.catestralCommunity === pcd.value && aep.politicalCommunity === politicalCommunity;
                    } else {
                        return aep.catestralCommunity === pcd.value;
                    }
                })
            )
        );
    }, [allExistingProperties, politicalCommunity]);

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={allExistingProperties.length === 0 || !mapInitialized}>
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress color='primary' size={100} />
                    <Typography variant='h6' component='div' sx={{ mt: 2 }}>
                        Karte wird geladen
                    </Typography>
                </div>
            </Backdrop>
            <div className={MapPageCSS.container}>
                <Grid container>
                    <Grid container justifyContent='center' alignItems='center' xs={12} columnSpacing={3} marginBlock={2}>
                        <Grid item xs={3}>
                            <DynamicSelect label='Gemeinde' data={politicalCommunityData} setValue={setPoliticalCommunity} />
                        </Grid>
                        <Grid item xs={3}>
                            <DynamicSelect label='Katastralgemeinde' data={catestralCommunityData} setValue={setCatestralCommunity} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={9} className={MapPageCSS.mapRow}>
                        <MapComponent />
                    </Grid>
                    <Grid item xs={12} sm={3} className={MapPageCSS.treeRow}>
                        <AreaTree
                            areaTreeData={areaTreeData}
                            selectedAreaIds={selectedAreaIds}
                            setSelectedAreaIds={setSelectedAreaIds}
                            inMapSelectedId={inMapSelectedId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AreaTable
                            showInMapButton={true}
                            propertyData={tablePropertyData}
                            leasedAreaData={tableLeasedAreaData}
                            leaseData={leaseData}
                            setLeaseData={setLeaseData}
                            selectedAreaIds={selectedAreaIds}
                            setSelectedAreaIds={setSelectedAreaIds}
                            showErrorInTable={showErrorInTable}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default MapPage;
