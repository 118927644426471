import { Typography } from '@mui/material';
import Config from '../../config/Config';
import HelperFunctions from '../helper/utils/helperFunctions/HelperFunctions';

const ContentNotOpened = () => {
    return (
        <div style={{ marginTop: '20px' }}>
            <Typography variant='h4'>Die Ausschreibung wurde noch nicht gestartet</Typography>
            <Typography variant='body1'>
                Das voraussichtliche Startdatum ist: {HelperFunctions.formatDateWithLeadingZero(Config.getStartDate())}
            </Typography>
        </div>
    );
};

export default ContentNotOpened;
