import { Modal, Box, IconButton } from '@mui/material';
import ImportantInfosCSS from '../css/ImportantInfos.module.css';
import CloseIcon from '@mui/icons-material/Close';
import HelperFunctions from '../../utils/helperFunctions/HelperFunctions';
import Config from '../../../../config/Config';

type ImportantInfosProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ImportantInfosModal = ({ isOpen, onClose }: ImportantInfosProps) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={ImportantInfosCSS.modalBox}>
                <Box className={ImportantInfosCSS.closeBox}>
                    <IconButton onClick={onClose} className={ImportantInfosCSS.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <div>
                    o Vergabe nach wirtschaftlichen, ökologischen und pastoralen Kriterien <br />o Pachtbeginn{' '}
                    {HelperFunctions.formatDateWithLeadingZero(Config.getContractStart())}, unbefristete Dauer - §2 Pachtvertrag <br />
                    o beiderseits jährlich kündbar - §2 Pachtvertrag <br />
                    o Wertsicherung VPI 2020, jährl. Anpassung - §3 Pachtvertrag <br />
                    o Flächentausch zur Erleichterung der Bewirtschaftung möglich - §5 Pachtvertrag <br />
                    o Die Pachtflächen sind vorwiegend Ackerflächen und einige Wiesen <br />
                    o Einziehungsauftrag für Pachtzins unbedingt erforderlich! <br />
                    o Anbote für mehrere aber auch einzelne Pachtflächen möglich <br />
                    o bisherige Bioflächen müssen bio bleiben - §7 Pachtvertrag <br />
                </div>
            </Box>
        </Modal>
    );
};

export default ImportantInfosModal;
