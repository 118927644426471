import { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
} from '@mui/material';
import AreaTableCSS from './AreaTable.module.css';
import { LeasedAreaData, LeaseData, PropertyData } from '../../../types/types';
import IconWithTooltip from '../tooltip/IconWithTooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CommunityData from '../utils/communityData/CommunityData';
import DeleteModal from '../modal/tsx/DeleteModal';
import { Visibility } from '@mui/icons-material';
import MapService from '../../../service/MapService';
import EuroIcon from '@mui/icons-material/Euro';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import HelperFunctions from '../utils/helperFunctions/HelperFunctions';

type AreaTableHeaderType = {
    id: string;
    label: string;
    tooltip: string;
};

type DataTableProps = {
    title?: string;
    showInMapButton: boolean;

    leasedAreaData: LeasedAreaData[];
    propertyData: PropertyData[];

    leaseData: LeaseData[];
    setLeaseData: (newLeaseData: LeaseData[]) => void;

    selectedAreaIds: string[];
    setSelectedAreaIds: (newSelectedAreaIds: string[]) => void;

    showErrorInTable: boolean;
};

const AreaTable = ({
    title,
    showInMapButton,
    leasedAreaData,
    propertyData,
    leaseData,
    setLeaseData,
    selectedAreaIds,
    setSelectedAreaIds,
    showErrorInTable,
}: DataTableProps) => {
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [rowToDelete, setRowToDelete] = useState<string>('');

    const dataTableHeaderRows: AreaTableHeaderType[] = [
        { id: 'owner', label: 'Eigentümer', tooltip: 'Eigentümer der Liegenschaft' },
        { id: 'cadastralCommunities', label: 'KG', tooltip: 'Katastralgemeinde' },
        { id: 'propertyNumber', label: 'GstNr', tooltip: 'Parzellennummer/Grundstücksnummer' },
        { id: 'area', label: 'Pachtflächengröße', tooltip: 'Gesamtfläche der Pachtfläche' },
        { id: 'bio', label: 'Betriebsführungsart', tooltip: 'Bio/Konventionell' },
        { id: 'offer', label: 'Angebot in €/ha', tooltip: 'Höhe des Pachtzins pro Hektar' },
        { id: 'actualPayment', label: 'Effektive Zahlung', tooltip: 'tatsächlich, jährlich fälliger Pachtzins' },
        { id: 'note', label: 'Anmerkung', tooltip: 'entscheidungsrelevante Informationen an den Verpächter' },
        {
            id: 'showAndDelete',
            label: showInMapButton ? 'Anzeigen/Löschen' : 'Löschen',
            tooltip: showInMapButton ? 'In Karte anzeigen / Angebot löschen' : 'Angebot löschen',
        },
    ];

    const changeOffer = (areaDataId: string, newOffer: string) => {
        const euroRegex = /^\d+(\.\d{1,2})?$/;
        const isValidEuro = euroRegex.test(newOffer);

        if (isValidEuro || newOffer === '') {
            const newLeaseData = leaseData.map((entry) => {
                if (entry.leasedAreaDataId === areaDataId) {
                    return { ...entry, offer: newOffer === '' ? null : Number(newOffer) };
                }
                return entry;
            });
            
            setLeaseData(newLeaseData);
        }
    };

    const changeNote = (areaDataId: string, newNote: string) => {
        const newLeaseData = leaseData.map((entry) => {
            if (entry.leasedAreaDataId === areaDataId) {
                return { ...entry, note: newNote };
            }
            return entry;
        });

        setLeaseData(newLeaseData);
    };

    const deleteRow = () => {
        setSelectedAreaIds(selectedAreaIds.filter((item) => item !== rowToDelete));
        setOpenDeleteModal(false);
    };

    const sortLeasedAreaData = (): LeasedAreaData[] => {
        leasedAreaData.sort((a, b) => {
            const firstProperty = propertyData.find((pd) => a.propertyDataIds[0] === pd.id);
            const secondProperty = propertyData.find((pd) => b.propertyDataIds[0] === pd.id);

            if (firstProperty.catestralCommunity === secondProperty.catestralCommunity)
                return firstProperty.propretyNumber.localeCompare(secondProperty.propretyNumber);

            return firstProperty.catestralCommunity.localeCompare(secondProperty.catestralCommunity);
        });

        return leasedAreaData;
    };

    if (!propertyData || !leasedAreaData || !leaseData) return <></>;

    return (
        <Box>
            <Box mb={2}>
                <Typography variant='h6'>{title}</Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {showErrorInTable && (
                                <TableCell className={AreaTableCSS.tableHeaderCell}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <b>Fehler</b>
                                        <IconWithTooltip tooltipText='Fehler in einer Zeile' />
                                    </div>
                                </TableCell>
                            )}
                            {dataTableHeaderRows.map((header) => (
                                <TableCell key={header.id} className={AreaTableCSS.tableHeaderCell}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <b>{header.label}</b>
                                        <IconWithTooltip tooltipText={header.tooltip} />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortLeasedAreaData().map((row, index) => (
                            <TableRow key={row.id} className={index % 2 === 0 ? AreaTableCSS.tableRowEven : AreaTableCSS.tableRowOdd}>
                                {showErrorInTable && (
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {row.hasError && (
                                            <Tooltip title={'Konventionelle Pächter dürfen keine Biofläche pachten!'}>
                                                <AgricultureIcon color='primary' />
                                            </Tooltip>
                                        )}
                                        {(leaseData.find((ld) => ld.leasedAreaDataId === row.id)?.offer === 0 ||
                                            leaseData.find((ld) => ld.leasedAreaDataId === row.id)?.offer === null) && (
                                            <Tooltip title={'Es muss ein Angebot gesetzt werden!'}>
                                                <EuroIcon color='primary' />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                )}
                                <TableCell>{row.owner}</TableCell>
                                <TableCell>
                                    {
                                        CommunityData.getCatestralCommunityData().find(
                                            (cc) => cc.value === propertyData.find((pd) => row.propertyDataIds.includes(pd.id))?.catestralCommunity
                                        )?.label
                                    }
                                </TableCell>
                                <TableCell>{propertyData.filter((pd) => row.propertyDataIds.includes(pd.id))?.[0].propretyNumber}</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {HelperFunctions.formatNumberWithThousandSeparator(row.area)} m<sup>2</sup>
                                </TableCell>
                                <TableCell>{row.bio}</TableCell>
                                <TableCell>
                                    <TextField
                                        key='offerKey'
                                        type='number'
                                        variant='outlined'
                                        size='small'
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: 'right' },
                                            },
                                        }}
                                        value={leaseData.find((ld) => ld.leasedAreaDataId === row.id)?.offer}
                                        onChange={(e) => changeOffer(row.id, e.target.value)}
                                        sx={{ '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' } }}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {HelperFunctions.formatNumberWithThousandSeparatorAndComma(
                                        HelperFunctions.calculateActualPayment(row, leaseData)
                                    )}{' '}
                                    €
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        key='offerKey'
                                        type='text'
                                        variant='outlined'
                                        size='small'
                                        multiline
                                        value={leaseData.find((ld) => ld.leasedAreaDataId === row.id)?.note}
                                        onChange={(e) => changeNote(row.id, e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    {showInMapButton && (
                                        <Tooltip title='In Karte anzeigen' arrow>
                                            <IconButton onClick={() => MapService.zoomToLeasedArea(row.featureId)}>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title='Angebot löschen' arrow>
                                        <IconButton
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setRowToDelete(row.id);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        {leasedAreaData.length !== 0 && (
                            <TableRow>
                                {showErrorInTable && <TableCell></TableCell>}
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <b>Summe:</b>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <b>
                                        {HelperFunctions.formatNumberWithThousandSeparatorAndComma(
                                            leasedAreaData
                                                .map((lad) => HelperFunctions.calculateActualPayment(lad, leaseData))
                                                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                        )}{' '}
                                        €
                                    </b>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <DeleteModal isOpen={openDeleteModal} onAccept={deleteRow} onClose={() => setOpenDeleteModal(false)} />
        </Box>
    );
};

export default AreaTable;
