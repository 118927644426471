import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const IconWithTooltip = ({ tooltipText }) => {
    return (
        <Tooltip title={tooltipText}>
            <IconButton aria-label='help' style={{ fontSize: '18px' }}>
                <InfoIcon style={{ fontSize: '18px' }} />
            </IconButton>
        </Tooltip>
    );
};

export default IconWithTooltip;
