import { FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import DataPageCSS from './DataPage.module.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { UserData } from '../../../types/types';
import ErrorIcon from '@mui/icons-material/Error';
import 'dayjs/locale/de';
import { toast } from 'react-toastify';

const inputFields = [
    { id: 'featuredTitle', label: 'Vorangestellter Titel', type: 'text', order: 0, required: false },
    { id: 'acatechTitle', label: 'Nachgestellter Titel', type: 'text', order: 0, required: false },
    { id: 'firstname', label: 'Vorname/Firmenbuchnummer', type: 'text', order: 0, required: true },
    { id: 'lastname', label: 'Nachname/Firmenname', type: 'text', order: 0, required: true },
    { id: 'street', label: 'Straße', type: 'text', order: 0, required: true },
    { id: 'streetNumber', label: 'Hausnummer', type: 'text', order: 0, required: true },
    { id: 'postCode', label: 'Postleitzahl', type: 'text', order: 0, required: true },
    { id: 'community', label: 'Ort', type: 'text', order: 0, required: true },
    { id: 'eMail', label: 'E-Mail', type: 'email', order: 0, required: true },
    { id: 'phoneNumber', label: 'Telefonnummer', type: 'text', order: 0, required: true },
    { id: 'birthdate', label: 'Geburtsdatum/Gründungsdatum', type: 'date', order: 1, required: true },
    { id: 'bioId', label: 'Kontrollstellen Code', type: 'text', order: 2, required: true },
    { id: 'companyNumber', label: 'LFBis', type: 'text', order: 3, required: true },
];

const radioFields = [
    {
        label: 'Bio',
        value: 'Bio',
    },
    {
        label: 'Konventionell',
        value: 'Konventionell',
    },
];

type DataPageProps = {
    userData: UserData;
    setUserData: (newUserData: UserData) => void;

    showDataMissingIcon: boolean;
};

const DataPage = ({ userData, setUserData, showDataMissingIcon }: DataPageProps) => {
    const getUserDataValue = (id: string) => {
        const value = userData[id];

        if (id === 'phoneNumber' && value) {
            if (value[0] === '+') {
                return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
            } else {
                return `${value.slice(0, 4)} ${value.slice(4)}`;
            }
        } else {
            return value;
        }
    };

    const setUserDataValue = (id: string, value: string | number | Dayjs) => {
        if (id === "eMail") {
            toast.error("Guter Versuch, aber Nein!");
        } else if (id === 'phoneNumber') {
            setUserData({
                ...userData,
                phoneNumber: value.toString().replaceAll(' ', ''),
            });
        } else if (id === 'bioOrConventional' && value === 'Konventionell') {
            setUserData({
                ...userData,
                bioOrConventional: value,
                bioId: null,
            });
        } else {
            setUserData({
                ...userData,
                [id]: value,
            });
        }
    };

    const eMailDataMissing = (eMailData): boolean => {
        return isNaN(eMailData?.$D) || isNaN(eMailData?.$D) || isNaN(eMailData?.$D);
    };

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Typography variant='h6'>Daten des Pächters</Typography>
            </Grid>

            {inputFields
                .filter((inputField) => inputField.order === 0)
                .map((inputField) => {
                    return (
                        <Grid key={inputField.id} item xs={12} sm={6}>
                            <TextField
                                key={inputField.id}
                                type={inputField.type}
                                variant='outlined'
                                label={inputField.label}
                                size='small'
                                className={DataPageCSS.textField}
                                disabled={inputField.id === 'eMail'}
                                InputProps={{
                                    endAdornment:
                                        showDataMissingIcon && inputField.required && !getUserDataValue(inputField.id) ? (
                                            <InputAdornment position='end'>
                                                <Tooltip title={'Pflichtfeld'}>
                                                    <ErrorIcon color='primary' />
                                                </Tooltip>
                                            </InputAdornment>
                                        ) : null,
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={getUserDataValue(inputField.id)}
                                onChange={(e) => setUserDataValue(inputField.id, e.target.value)}
                                sx={{ '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' } }}
                            />
                        </Grid>
                    );
                })}

            {inputFields
                .filter((inputField) => inputField.order === 1)
                .map((inputField) => {
                    return (
                        <Grid key={inputField.id} item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                                <DatePicker
                                    label={inputField.label}
                                    format="DD.MM.YYYY"
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            error: false,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                            InputProps: showDataMissingIcon &&
                                                eMailDataMissing(getUserDataValue(inputField.id)) && {
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Tooltip title={'Pflichtfeld'}>
                                                                <ErrorIcon color='primary' />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                },
                                        },
                                    }}
                                    className={`${DataPageCSS.textField}`}
                                    value={getUserDataValue('birthdate')}
                                    onChange={(e) => setUserDataValue(inputField.id, e)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    );
                })}

            <Grid item xs={12}>
                <Typography variant='h6'>
                    Landwirtschaftliche Angaben{' '}
                    {showDataMissingIcon && !getUserDataValue('bioOrConventional') && (
                        <Tooltip title={'Pflichtfeld'}>
                            <ErrorIcon color='primary' />
                        </Tooltip>
                    )}
                </Typography>
                <RadioGroup
                    name='agricultureStatus'
                    value={getUserDataValue('bioOrConventional')}
                    onChange={(e) => setUserDataValue('bioOrConventional', e.target.value)}
                    style={{ marginBottom: '10px' }}
                >
                    {radioFields.map((radioField, index) => (
                        <Grid key={`${radioField.label}_${index}`} container rowSpacing={3}>
                            <Grid key={radioField.label + '_label'} item xs={12} sm={2}>
                                <FormControlLabel
                                    key={radioField.label + '_control'}
                                    value={radioField.value}
                                    control={<Radio />}
                                    label={radioField.label}
                                    className={DataPageCSS.radio}
                                />
                            </Grid>
                            {radioField.value === 'Bio' &&
                                getUserDataValue('bioOrConventional') === 'Bio' &&
                                inputFields
                                    .filter((inputField) => inputField.order === 2)
                                    .map((inputField) => (
                                        <Grid key={inputField.label + '_grid'} item xs={12} sm={3}>
                                            <TextField
                                                key={inputField.label + '_key2'}
                                                type={inputField.type}
                                                variant='outlined'
                                                label={inputField.label}
                                                size='small'
                                                className={DataPageCSS.textField}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    endAdornment:
                                                        showDataMissingIcon && !getUserDataValue(inputField.id) ? (
                                                            <InputAdornment position='end'>
                                                                <Tooltip title={'Pflichtfeld'}>
                                                                    <ErrorIcon color='primary' />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ) : null,
                                                }}
                                                value={getUserDataValue(inputField.id)}
                                                onChange={(e) => setUserDataValue(inputField.id, e.target.value)}
                                            />
                                        </Grid>
                                    ))}
                        </Grid>
                    ))}
                </RadioGroup>

                {inputFields
                    .filter((inputField) => inputField.order === 3)
                    .map((inputField) => {
                        return (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    key={inputField.id}
                                    type={inputField.type}
                                    variant='outlined'
                                    label={inputField.label}
                                    size='small'
                                    className={DataPageCSS.textField}
                                    disabled={inputField.id === 'eMail'}
                                    InputProps={{
                                        endAdornment:
                                            showDataMissingIcon && !getUserDataValue(inputField.id) ? (
                                                <InputAdornment position='end'>
                                                    <Tooltip title={'Pflichtfeld'}>
                                                        <ErrorIcon color='primary' />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ) : null,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    value={getUserDataValue(inputField.id)}
                                    onChange={(e) => setUserDataValue(inputField.id, e.target.value)}
                                    sx={{ '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' } }}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </Grid>
    );
};

export default DataPage;
