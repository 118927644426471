import { Typography } from '@mui/material';

const ContentClosed = () => {
    return (
        <div style={{ marginTop: '20px' }}>
            <Typography variant='h4'>Die Ausschreibung ist beendet</Typography>
            <Typography variant='body1'>Danke, dass Sie an der Ausschreibung teilgenommen haben.</Typography>
            <Typography variant='body1'>
                Für weitere Informationen besuchen Sie uns unter <a href='https://www.martinus.at/institution/5002/verpachtung'>https://www.martinus.at/institution/5002/verpachtung</a>.
            </Typography>
        </div>
    );
};

export default ContentClosed;
