import { Grid, TextField, Typography } from '@mui/material';
import SummaryPageCSS from './SummaryPage.module.css';
import dayjs, { Dayjs } from 'dayjs';
import { LeasedAreaData, LeaseData, PropertyData, UserData } from '../../../types/types';
import AreaTable from '../../helper/table/AreaTable';

type SummaryPageProps = {
    userData: UserData;
    setUserData: (newUserData: UserData) => void;

    tablePropertyData: PropertyData[];
    tableLeasedAreaData: LeasedAreaData[];

    leaseData: LeaseData[];
    setLeaseData: (newLeaseData: LeaseData[]) => void;

    selectedAreaIds: string[];
    setSelectedAreaIds: (newSelectedAreaIds: string[]) => void;

    showErrorInTable: boolean;
};

const SummaryPage = ({
    userData,
    setUserData,
    tablePropertyData,
    tableLeasedAreaData,
    leaseData,
    setLeaseData,
    selectedAreaIds,
    setSelectedAreaIds,
    showErrorInTable,
}: SummaryPageProps) => {
    const setUserDataValue = (id: string, value: string | number | Dayjs) => {
        setUserData({
            ...userData,
            [id]: value,
        });
    };

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={3}>
                <Typography variant='h6'>Daten des Pächters</Typography>
                <Typography variant='body1'>
                    {userData.featuredTitle} {userData.firstname} {userData.lastname} {userData.acatechTitle}
                </Typography>
                <Typography variant='body1'>{userData.birthdate && dayjs(userData.birthdate).format('DD.MM.YYYY')}</Typography>
                <Typography variant='body1'>
                    {userData.street} {userData.streetNumber}
                </Typography>
                <Typography variant='body1'>
                    {userData.postCode} {userData.community}
                </Typography>
                <Typography variant='body1'>{userData.eMail}</Typography>
                <Typography variant='body1'>{userData.phoneNumber}</Typography>
                <Typography variant='body1'>{userData.bioOrConventional}</Typography>
                <Typography variant='body1'>LFBis: {userData.companyNumber}</Typography>
                {userData.bioId && <Typography variant='body1'>Kontrollstellen Code: {userData.bioId}</Typography>}
            </Grid>
            <Grid item xs={9} className={SummaryPageCSS.noteRow}>
                <TextField
                    key='AnmerkungKey'
                    type='text'
                    variant='outlined'
                    label='Hier können Sie zusätzliche Anmerkung zu Ihrem Angebot notieren.'
                    size='small'
                    value={userData.note}
                    multiline
                    rows={10}
                    InputProps={{
                        className: SummaryPageCSS.noteTextArea,
                    }}
                    className={SummaryPageCSS.note}
                    onChange={(e) => setUserDataValue('note', e.target.value)}
                    sx={{
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' },
                    }}
                />
            </Grid>
            <Grid item xs={12} className={SummaryPageCSS.table}>
                <AreaTable
                    title='Flächen'
                    showInMapButton={false}
                    propertyData={tablePropertyData}
                    leasedAreaData={tableLeasedAreaData}
                    leaseData={leaseData}
                    setLeaseData={setLeaseData}
                    selectedAreaIds={selectedAreaIds}
                    setSelectedAreaIds={setSelectedAreaIds}
                    showErrorInTable={showErrorInTable}
                />
            </Grid>
        </Grid>
    );
};

export default SummaryPage;
