/* eslint-disable import/no-anonymous-default-export */
import BaseService from '../api/BaseService';
import EventEmitter from '../components/helper/utils/event/EventEmitter';
import Config from '../config/Config';
import '../api/rmdata.d.ts';

const mapName = Config.getMapName();

let geoWeb: GeoWeb = null;

export default {
    initMap(): Promise<void> {
        return new Promise((resolve) => {
            rmdata.InitAPIv3(BaseService.getBearer(), `${Config.getServiceURL()}/api/out/rmdata.api.js`, '').then((result) => {
                geoWeb = new rmdata.GeoWeb('myMapDiv', 'loaderele');

                geoWeb.InitMapByNameV2(mapName).then(() => {
                    // this.zoomToExtendWithWindow(eisenstadtWindow);

                    geoWeb.GetThemeNamesExtendedByMapName(mapName).then((themes) => {
                        geoWeb.ApplyTheme(
                            themes.find((theme) => theme.name === 'Verpachtungsportal'),
                            [],
                            null,
                            false,
                            false
                        );
                    });

                    geoWeb.EventHub.on('SELECTIONCHANGED', (res) => {
                        EventEmitter.publish('SELECTIONCHANGED', res['SelectedFeatures']?.[0]?.['properties']?.['Internal~AssetId']);
                    });

                    resolve();
                });
            });
        });
    },
    zoomToExtendWithWindow(window: number[]) {
        this.zoomToExtendWithCoordinates(window[0], window[1], window[2], window[3]);
    },
    zoomToExtendWithCoordinates(left: number, bottom: number, right: number, top: number) {
        const window = [left, bottom, right, top];
        geoWeb.ZoomExtent(window);
    },
    zoomToCommunity(searchType: string, communityName: string) {
        geoWeb
            .DoSearch(searchType, communityName)
            .then((res) => {
                if (res?.features) {
                    geoWeb.ZoomToObjects(res.features);
                }
            })
            .catch((error) => {});
    },
    zoomToPoliticalCommunity(pcName: string) {
        this.zoomToCommunity('Politische Gemeinde Suche', pcName);
    },
    zoomToCatastralCommunity(ccName: string) {
        this.zoomToCommunity('Katastralgemeinde Suche', ccName);
    },
    convertFeatureArrayToFeatureCollection(features: any) {
        return {
            type: 'FeatureCollection',
            features: features,
        };
    },
    zoomToLeasedArea(featureId: number) {
        if (featureId)
            geoWeb
                .GetFeatureById(Config.getDataSourceId(), featureId)
                .then((res) => {
                    geoWeb.ShowObjects(res['features'], true);
                })
                .catch((error) => {});
    },
};
