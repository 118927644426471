import { Button, Modal, Box, Typography, Grid } from '@mui/material';
import ModalCSS from "../css/Modal.module.css"

type DeleteModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
};

const DeleteModal = ({ isOpen, onClose, onAccept }: DeleteModalProps) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={ModalCSS.modalBox}>
                <Grid container direction='column' spacing={1} className={ModalCSS.modalContent}>
                    <Grid item>
                        <Typography variant='h4' className={ModalCSS.modalTitle}>
                            Bestätigung zum <b>Löschen</b>!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body1' className={ModalCSS.modalText}>
                            Bestätigen Sie bitte, ob Sie tatsächlich den Antrag für die Pachtfläche löschen möchten?
                        </Typography>
                    </Grid>
                    <Grid item className={ModalCSS.buttonsContainer}>
                        <Button variant='outlined' color='error' onClick={onAccept}>
                            Löschen
                        </Button>
                        <Button variant='outlined' color="secondary" onClick={onClose} sx={{ ml: 2 }}>
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DeleteModal;
