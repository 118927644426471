/* eslint-disable import/no-anonymous-default-export */

import BaseService from '../api/BaseService';
import Config from '../config/Config';
import { Asset, AssetWithRelations } from '../types/types';

const InventoryBaseUrl = `${Config.getServiceURL()}/api/InventoryExternal`;

const assetDbId = Config.getAssetDbId();

export default {
    searchUser(eMail: string) {
        return BaseService.postJson<Asset>(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Search?maxItemCount=5000`, {
            Asset: {
                TypeName: 'PAECHTER',
                Attributes: {
                    E_MAIL: eMail,
                },
            },
            SubAssetQueries: [],
        });
    },
    searchProperty(politicalCommunity: string, catestralCommunity: string) {
        return BaseService.postJson<Asset>(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Search?maxItemCount=5000`, {
            Asset: {
                TypeName: 'LIEGENSCHAFT',
                Attributes: {
                    GEMEINDE: politicalCommunity,
                    KATASTRALGEMEINDE: catestralCommunity,
                },
            },
            SubAssetQueries: [],
        });
    },
    getAsset(assetId: string): Promise<any> {
        return BaseService.getJson(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets/${assetId}`);
    },
    updateAsset(asset: Asset) {
        return BaseService.putJson(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets`, asset);
    },
    createAssetWithRelation(assetWithRelation: AssetWithRelations) {
        return BaseService.postJson(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets/Relations`, assetWithRelation);
    },
    updateAssetWithRelation(assetWithRelation: AssetWithRelations) {
        return BaseService.putJson(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets/Relations`, assetWithRelation);
    },
    getAllRelatedAssets(assetId: string, relationType: string): Promise<any> {
        return BaseService.getJson(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets/${assetId}/Relations/${relationType}`);
    },
    deleteAsset(assetId: string) {
        return BaseService.Delete(`${InventoryBaseUrl}/AssetDBs/${assetDbId}/Assets/${assetId}`);
    },
};
