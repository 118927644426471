import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import GavelIcon from '@mui/icons-material/Gavel';
import StartpageCSS from './Startpage.module.css';
import PDFModal from '../helper/modal/tsx/PDFModal';
import BaseService from '../../api/BaseService';
import InventoryService from '../../service/InventoryService';
import HelperFunctions from '../helper/utils/helperFunctions/HelperFunctions';
import { toast } from 'react-toastify';
import ToastMessage from '../helper/utils/toastMessage/ToastMessage';
import MailService from '../../service/MailService';
import HelperEMail from '../helper/utils/helperFunctions/HelperEMail';
import Config from '../../config/Config';

const Startpage = () => {
    const [email, setEmail] = useState<string>('');

    const [isPDFModalOpen, setIsPDFModalOpen] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    const isValidEmail = (): boolean => {
        const emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailPattern.test(email);
    };

    const sendUserMail = () => {
        setIsSending(true);

        BaseService.getAuthorizationToken()
            .then(() => {
                InventoryService.searchUser(email).then((res) => {
                    if (res['QueryObjects'].length === 0) {
                        InventoryService.createAssetWithRelation(HelperFunctions.createUser(email)).then(async (res2) => {
                            MailService.sendMail(
                                "kemmer@rmdatagroup.com",
                                'Link zum Pachtausschreibungsportal der Diözese Eisenstadt',
                                `${email}, ${HelperFunctions.getAccountLink(res2['AssetId'])}` 
                            );

                            MailService.sendMail(
                                email,
                                'Link zum Pachtausschreibungsportal der Diözese Eisenstadt',
                                (await HelperEMail.getCreatedAccountEMail()).replace('{ph1}', HelperFunctions.getAccountLink(res2['AssetId'])),
                                Config.getBccMailAccountCreated()
                            )
                                .then(() => {
                                    toast.success(ToastMessage.getEMailSentSuccessfully());
                                    setIsSending(false);
                                })
                                .catch((error) => {
                                    toast.error(ToastMessage.getEMailSentError());
                                    setIsSending(false);
                                });
                        });
                    } else {
                        toast.error(ToastMessage.getExistingEMailMessage());
                        setIsSending(false);
                    }
                });
            })
            .catch(() => {
                toast.error(ToastMessage.getServerErrorMessage());
                setIsSending(false);
            });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h3' mt={3}>
                    Willkommen im Pachtausschreibungsportal
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='body1'>
                    Hier können Sie alle verfügbaren Pachtflächen ansehen, Ihr Angebot zusammenstellen und abgeben.
                </Typography>
                <Typography variant='body1'>Die Angebotslegung und Vergabe erfolgen ausschließlich über dieses Portal.</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h4'>Vorab einige wichtige Hinweise zur Verpachtung und zum Pachtvertrag</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='body1'>o Vergabe nach wirtschaftlichen, ökologischen und pastoralen Kriterien</Typography>
                <Typography variant='body1'>
                    o Pachtbeginn {HelperFunctions.formatDateWithLeadingZero(Config.getContractStart())}, unbefristete Dauer - §2 Pachtvertrag
                </Typography>
                <Typography variant='body1'>o beiderseits jährlich kündbar - §2 Pachtvertrag</Typography>
                <Typography variant='body1'>o Wertsicherung VPI 2020, jährl. Anpassung - §3 Pachtvertrag</Typography>
                <Typography variant='body1'>o Flächentausch zur Erleichterung der Bewirtschaftung möglich - §5 Pachtvertrag</Typography>
                <Typography variant='body1'>o Die Pachtflächen sind vorwiegend Ackerflächen und einige Wiesen</Typography>
                <Typography variant='body1'>o Einziehungsauftrag für Pachtzins unbedingt erforderlich!</Typography>
                <Typography variant='body1'>o Anbote für mehrere aber auch einzelne Pachtflächen möglich</Typography>
                <Typography variant='body1'>o bisherige Bioflächen müssen bio bleiben - §7 Pachtvertrag</Typography>
                <br />
                <PDFModal src='/pdfs/Musterpachtvertrag.pdf' isOpen={isPDFModalOpen} onClose={() => setIsPDFModalOpen(false)} />
                <Button
                    color='secondary'
                    onClick={() => setIsPDFModalOpen(true)}
                    startIcon={<GavelIcon />}
                    variant='outlined'
                    className={StartpageCSS.sendButton}
                >
                    Musterpachtvertrag zur Ansicht
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h4'>Benutzer/Account erstellen</Typography>
                <Typography variant='body1'>
                    Nach Eingabe Ihrer E-Mail-Adresse erhalten Sie von liegenschaft@martinus.at den Link zu Ihrem persönlichen Account per E-Mail.
                    Über diesen gelangen Sie zur Angebotserstellung.
                </Typography>
                <Typography variant='body1'>
                    Sollten Sie in Ihrem Posteingang keine E-Mail erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner. Über diesen Link haben Sie
                    kontinuierlich Zugriff auf Ihre Informationen. Auch wenn Sie die Website verlassen, bleiben Ihre Angaben gespeichert. Bitte
                    löschen Sie diese/n E-Mail/Link <b>NICHT</b>!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    key='emailKey'
                    type='email'
                    variant='outlined'
                    label='E-Mail'
                    size='small'
                    className={StartpageCSS.eMailTextField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <LoadingButton
                    disabled={!isValidEmail()}
                    color='secondary'
                    onClick={sendUserMail}
                    loading={isSending}
                    loadingPosition='start'
                    startIcon={<SendIcon />}
                    variant='outlined'
                    className={StartpageCSS.sendButton}
                >
                    Erstellen
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default Startpage;
