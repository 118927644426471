import AppCSS from './App.module.css'; // Import your CSS module
import { ThemeProvider } from '@mui/material';
import Content from './components/layout/Content';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

import './api/rmdata.api.css';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <div className={AppCSS.flexContainer}>
                <div className={AppCSS.headerRow}>
                    <Header />
                </div>
                <div className={AppCSS.contentRow}>
                    <Content />
                </div>
                <div className={AppCSS.footerRow}>
                    <Footer />
                </div>
            </div>

            <ToastContainer
                position='bottom-center'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
        </ThemeProvider>
    );
};

export default App;
