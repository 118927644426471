/* eslint-disable import/no-anonymous-default-export */
import { UserData } from './../../../../types/types.d';
import { LeaseData, LeasedAreaData, PropertyData } from '../../../../types/types';
import CommunityData from '../communityData/CommunityData';
import HelperFunctions from './HelperFunctions';
import dayjs from 'dayjs';

const getEmailContent = async (filePath: string): Promise<string | null> => {
    try {
        const response = await fetch(filePath);
        const htmlContent = await response.text();
        return htmlContent;
    } catch (error) {
        console.error(`Error fetching HTML content for ${filePath}:`, error);
        return null;
    }
};

const getUserDataForMail = (userData: UserData) => {
    const bioId = userData?.bioId !== null && userData?.bioId !== '' ? `Kontrollstellen Code: ${userData.bioId}<br/>` : '';
    const note =
        userData?.note !== null && userData?.note !== "" ?
        `
            <br/><hr><br/>
            Anmerkung:<br/><br/>
            ${userData.note} <br/>
        `
        :
        ""
    ;

    return `<div> 
        ${userData?.featuredTitle ? `${userData.featuredTitle} ` : ''}${userData.firstname} ${userData.lastname} ${
        userData?.acatechTitle ? `${userData.acatechTitle}` : ''
    } <br/>
        ${userData.birthdate && dayjs(userData.birthdate).format('DD.MM.YYYY')} <br/>
        ${userData.street} ${userData.streetNumber} <br/>
        ${userData.postCode} ${userData.community} <br/>
        ${userData.eMail} <br/>
        ${userData.phoneNumber} <br/>
        ${userData.bioOrConventional} <br/>
        LFBis: ${userData.companyNumber} <br/>
        ${bioId}
        ${note}
    </div>`;
};

const getEMailTable = (propertyData: PropertyData[], leasedAreaData: LeasedAreaData[], leaseData: LeaseData[]) => {
    let rows: string = '';

    leasedAreaData.forEach((lad) => {
        const row = `
            <tr>
                <td> 
                    ${lad.owner}
                </td>
                <td>
                    ${
                        CommunityData.getCatestralCommunityData().find(
                            (cc) => cc.value === propertyData.find((pd) => lad.propertyDataIds.includes(pd.id))?.catestralCommunity
                        )?.label
                    }
                </td>
                <td>
                    ${propertyData.filter((pd) => lad.propertyDataIds.includes(pd.id))?.[0].propretyNumber}
                </td>
                <td style="text-align: right;">
                    ${HelperFunctions.formatNumberWithThousandSeparator(lad.area)} m<sup>2</sup>
                </td>
                <td> 
                    ${lad.bio}
                </td>
                <td style="text-align: right"> 
                    ${leaseData.find((ld) => ld.leasedAreaDataId === lad.id)?.offer.toFixed(2)} €
                </td>
                <td style="text-align: right"> 
                    ${HelperFunctions.formatNumberWithThousandSeparatorAndComma(HelperFunctions.calculateActualPayment(lad, leaseData))} €
                </td>
                <td> 
                    ${leaseData.find((ld) => ld.leasedAreaDataId === lad.id)?.note}
                </td>
            </tr>
        `;

        rows = rows.concat(row);
    });

    rows = rows.concat(
        `
            <tr>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td style="text-align: center">
                    <b>Summe:</b>
                </td>
                <td style="text-align: right">
                    <b>
                        ${HelperFunctions.formatNumberWithThousandSeparatorAndComma(
                            leasedAreaData
                                .map((lad) => HelperFunctions.calculateActualPayment(lad, leaseData))
                                .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                        )} €
                    </b>
                </td>
                <td/>
            </tr>
        `
    );

    return rows;
};

export default {
    getCreatedAccountEMail: async (): Promise<string | null> => {
        return await getEmailContent('/emails/createdAccount.html');
    },
    getFinishedEMail: async (
        propertyData: PropertyData[],
        leasedAreaData: LeasedAreaData[],
        leaseData: LeaseData[],
        userData: UserData
    ): Promise<string | null> => {
        let eMailContent = await getEmailContent('/emails/finished.html');

        let userDataForMail: string = getUserDataForMail(userData);
        let tableRows: string = getEMailTable(propertyData, leasedAreaData, leaseData);

        eMailContent = eMailContent.replace('{ph1}', userDataForMail);
        eMailContent = eMailContent.replace('{ph2}', tableRows);

        return eMailContent;
    },
    getRecoveryEMail: async (): Promise<string | null> => {
        return await getEmailContent('/emails/recovery.html');
    },
};
