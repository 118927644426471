/* eslint-disable import/no-anonymous-default-export */

import dayjs from 'dayjs';
import { LeaseData, LeasedAreaData, PropertyData, UserData } from '../../../../types/types';

export type UserDataGerman = {
    VGTITEL: string;
    NGTITEL: string;
    VORNAME: string;
    NACHNAME: string;
    GEBURTSDATUM: string;
    STRASSE: string;
    HAUSNUMMER: string;
    POSTLEIZAHL: string;
    ORT: string;
    E_MAIL: string;
    TELEFONNUMMER: string;
    BETRIEBSART: string;
    BETRIEBSNUMMER: string;
    KUNDENNUMMER_DER_KONTROLLSTELLE: string;
    ANMERKUNG: string;
    STATUS: string;
};

export type PropertyDataGerman = {
    id: string;
    title: string;
    LIEGENSCHAFTSNUMMER: string;
    GEMEINDE: string;
    KATASTRALGEMEINDE: string;
};

export type PropertyDataGermanV2 = {
    AssetId: string;
    Title: string;
    Attributes: {
        LIEGENSCHAFTSNUMMER: string;
        GEMEINDE: string;
        KATASTRALGEMEINDE: string;
    };
};

export type LeasedAreaDataGerman = {
    AssetId: string;
    Title: string;
    Attributes: {
        ANMERKUNG: string;
        BESITZKATEGORIE: string;
        BIS: string;
        EURO_GESAMT: string;
        EURO_PRO_HA: string;
        FLAECHE__M__: number;
        KIRCHLICHE_EIGENTUEMER: string;
        PACHTFLAECHENNUMMER: string;
        RIED: string;
        BIO: string;
        FEATUREID: number;
    };
};

export type LeaseDataGerman = {
    AssetId: string;
    Attributes: {
        ANGEBOT_IN___HA: number;
        ANMERKUNG: string;
    };
};

export default {
    convertUserDataToEnglish(userDataGerman: UserDataGerman): UserData {
        return {
            featuredTitle: userDataGerman.VGTITEL,
            acatechTitle: userDataGerman.NGTITEL,
            firstname: userDataGerman.VORNAME,
            lastname: userDataGerman.NACHNAME,
            street: userDataGerman.STRASSE,
            streetNumber: userDataGerman.HAUSNUMMER,
            postCode: userDataGerman.POSTLEIZAHL,
            community: userDataGerman.ORT,
            eMail: userDataGerman.E_MAIL,
            phoneNumber: userDataGerman.TELEFONNUMMER,
            birthdate: dayjs(userDataGerman.GEBURTSDATUM),
            bioOrConventional: userDataGerman.BETRIEBSART,
            bioId: userDataGerman.KUNDENNUMMER_DER_KONTROLLSTELLE,
            companyNumber: userDataGerman.BETRIEBSNUMMER,
            note: userDataGerman.ANMERKUNG,
            status: userDataGerman.STATUS,
        } as UserData;
    },
    convertPropertyDataToEnglish(propertyData: PropertyDataGerman[]): PropertyData[] {
        return propertyData.map((pd) => {
            return {
                id: pd.id,
                title: pd.title,
                propretyNumber: pd.LIEGENSCHAFTSNUMMER,
                politicalCommunity: pd.GEMEINDE,
                catestralCommunity: pd.KATASTRALGEMEINDE,
            } as PropertyData;
        }) as PropertyData[];
    },
    convertPropertyDataToEnglishV2(propertyData: PropertyDataGermanV2[]): PropertyData[] {
        return propertyData.map((pd) => {
            return {
                id: pd.AssetId,
                title: pd.Title,
                propretyNumber: pd.Attributes.LIEGENSCHAFTSNUMMER,
                politicalCommunity: pd.Attributes.GEMEINDE,
                catestralCommunity: pd.Attributes.KATASTRALGEMEINDE,
            } as PropertyData;
        }) as PropertyData[];
    },
    convertLeasedAreaDataEnglish(propertyDataIds: string[], leasedAreaDataGerman: LeasedAreaDataGerman[]): LeasedAreaData[] {
        return leasedAreaDataGerman.map((lad) => {
            return {
                propertyDataIds: propertyDataIds,
                id: lad.AssetId,
                leasedAreaNumber: lad.Attributes.PACHTFLAECHENNUMMER,
                owner: lad.Attributes.BESITZKATEGORIE,
                ried: lad.Attributes.RIED,
                area: lad.Attributes.FLAECHE__M__,
                bio: lad.Attributes.BIO,
                featureId: lad.Attributes.FEATUREID,
            } as LeasedAreaData;
        }) as LeasedAreaData[];
    },
    convertLeaseDataToEnglish(leasedAreaDataId: string, leaseDataGerman: LeaseDataGerman): LeaseData {
        return {
            id: leaseDataGerman.AssetId,
            leasedAreaDataId: leasedAreaDataId,
            offer: leaseDataGerman.Attributes.ANGEBOT_IN___HA,
            note: leaseDataGerman.Attributes.ANMERKUNG,
        } as LeaseData;
    },
};
