import { Button, Modal, Box, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import ModalCSS from '../css/Modal.module.css';

type ReactivateUserModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
};

const ReactivateUserModal = ({ isOpen, onClose, onAccept }: ReactivateUserModalProps) => {
    const [accepted, setAccepted] = useState(false);

    const handleAcceptClick = () => {
        if (accepted) {
            onAccept();
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={ModalCSS.modalBox}>
                <Grid container direction='column' spacing={1} className={ModalCSS.modalContent}>
                    <Grid item>
                        <Typography variant='h4' className={ModalCSS.modalTitle}>
                            Angebot stornieren
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body1' className={ModalCSS.modalText}>
                            Wenn Sie Ihr Angebot stornieren, dürfen Sie nicht vergessen dieses am Ende wieder erneut abzusenden!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <label htmlFor='agbCheckbox' className={ModalCSS.checkboxLabel}>
                            <input
                                type='checkbox'
                                id='agbCheckbox'
                                checked={accepted}
                                onChange={(e) => setAccepted(e.target.checked)}
                                className={ModalCSS.checkboxInput}
                            />
                            <span>Ich bestätige hiermit, dass mein Angebot storniert werden soll.</span>
                        </label>
                    </Grid>
                    <Grid item className={ModalCSS.buttonsContainer}>
                        <Button variant='outlined' color='success' onClick={handleAcceptClick} disabled={!accepted}>
                            Stornieren
                        </Button>
                        <Button variant='outlined' color='error' onClick={onClose} sx={{ ml: 2 }}>
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ReactivateUserModal;
