/* eslint-disable import/no-anonymous-default-export */

export default {
    getServerErrorMessage(): string {
        return 'Serverfehler, versuchen Sie es später erneut!';
    },
    getExistingEMailMessage(): string {
        return 'E-Mail ist bereits vergeben!';
    },
    getUserDataErrorMessage(): string {
        return 'Es ist ein Fehler bei der Benutzerabfrage aufgetreten!';
    },
    getDynamicSelectErrorMessage(label: string): string {
        return `Es ist ein Fehler bei der Abfrage von ${label} aufgetreten!`;
    },
    getDataNotSavedMessage(): string {
        return 'Es ist ein Fehler bei der Datenspeicherung aufgetreten!';
    },
    getMissigeAttributesErrorMessage(): string {
        return 'Es müssen alle Pflichtfelder befüllt sein!';
    },
    getDataError(): string {
        return 'Es ist ein Fehler bei der Datenabfrage aufgetreten!';
    },
    getNotAllOfferSetError(): string {
        return 'Es sind nicht alle Angebote gesetzt oder es sind keine Angebote vorhanden oder es befindet sich ein Fehler in einer Zeile der Tabelle!';
    },
    getEMailSentSuccessfully(): string {
        return 'E-Mail wurde erfolgreich versandt!';
    },
    getEMailSentError(): string {
        return 'Es ist ein Fehler beim Versandt der E-Mail aufgetreten!';
    },
    getUserDataSavedMessage(): string {
        return 'Benutzerdaten gespeichert!';
    },
    getLeaseDataSavedMessage(): string {
        return 'Antragsdaten gespeichert!';
    },
};
