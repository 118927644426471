/* eslint-disable import/no-anonymous-default-export */

//MARTINUS
export default {
    getMapName(): string {
        return 'Verpachtung';
    },
    getAssetDbId(): string {
        return '6de873ed-9ea2-4181-8bb9-37df88ca0975';
    },
    getServiceURL(): string {
        return 'https://martinus-gis.rmdatacloud.com/service';
    },
    getStartDate(): Date {
        return new Date(2024, 3, 8);
    },
    getContractStart(): Date {
        return new Date(2025, 0, 1);
    },
    getEndDate(): Date {
        return new Date(2024, 4, 6); //ein Tag mehr
    },
    getEndOfEvaluationDate(): Date {
        return new Date(2024, 5, 30);
    },
    getBccMailAccountCreated(): string {
        return 'liegenschaft@martinus.at';
    },
    getBccMailDataFinished(): string {
        return 'liegenschaft@martinus.at';
    },
    getBccMailDataRestored(): string {
        return 'liegenschaft@martinus.at';
    },
    getDataSourceId(): string {
        return '9da46888-ae9d-4f4a-b3ed-e30a3e8d5a5a';
    },
    getEndOfMaintencane(): string {
        return "15:00 Uhr";
    },
    getActivateMaintenance(): boolean {
        return false;
    },
};

//LOCAL
// export default {
//     getMapName(): string {
//         return 'VerpachtungsKarte';
//     },
//     getAssetDbId(): string {
//         return 'bcb4345c-f870-4041-952c-52fbedb294a9';
//     },
//     getServiceURL(): string {
//         return 'http://127.0.0.1:65300';
//     },
// };
