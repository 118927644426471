import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import { AreaTreeData } from '../../../types/types';
import DeleteModal from '../modal/tsx/DeleteModal';
import { Visibility } from '@mui/icons-material';
import MapService from '../../../service/MapService';
import HelperFunctions from '../utils/helperFunctions/HelperFunctions';

type AreaTreeProps = {
    areaTreeData: AreaTreeData;

    selectedAreaIds: string[];
    setSelectedAreaIds: (newAreaIds: string[]) => void;

    inMapSelectedId: string;
};

const AreaTree = ({ areaTreeData, selectedAreaIds, setSelectedAreaIds, inMapSelectedId }: AreaTreeProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [rowToDelete, setRowToDelete] = useState<string>('');

    useEffect(() => {
        const defaultExpanded = [];

        defaultExpanded.push(areaTreeData.id);

        setExpanded(defaultExpanded);
    }, [areaTreeData.id]);

    useEffect(() => {
        const treeNode = areaTreeData.children.find((l1) => {
            return l1.children.find((l2) => {
                return l2.id === inMapSelectedId;
            });
        });

        if (treeNode) setExpanded((prev) => [...prev, treeNode.id]);
    }, [areaTreeData, inMapSelectedId]);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const addSelected = (nodeId: string) => {
        setSelectedAreaIds([...selectedAreaIds, nodeId]);
    };

    const removeSelected = () => {
        setSelectedAreaIds(selectedAreaIds.filter((item) => item !== rowToDelete));
        setOpenDeleteModal(false);
    };

    const handleCheckboxChange = (id) => {
        if (selectedAreaIds.includes(id)) {
            setRowToDelete(id);
            setOpenDeleteModal(true);
        } else {
            addSelected(id);
        }
    };

    const renderTree = (nodes: AreaTreeData) => {
        if (!nodes) return null;

        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            {nodes.name} {nodes.bio !== undefined ? `(${nodes.bio})` : ''}{' '}
                            {nodes.area !== undefined ? `(${HelperFunctions.formatNumberWithThousandSeparator(nodes.area)} m²)` : ''}
                        </span>
                        {nodes.isLeasedArea ? (
                            <>
                                <Tooltip title='Auswahl für Pachtangebot' arrow>
                                    <Checkbox checked={selectedAreaIds.includes(nodes.id)} onChange={() => handleCheckboxChange(nodes.id)} />
                                </Tooltip>
                                <Tooltip title='In Karte anzeigen' arrow>
                                    <IconButton onClick={() => MapService.zoomToLeasedArea(nodes.featureId)}>
                                        <Visibility />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : null}
                    </div>
                }
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
            </TreeItem>
        );
    };

    return (
        <>
            <TreeView
                aria-label='rich object'
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={[...selectedAreaIds, inMapSelectedId]}
                onNodeToggle={handleToggle}
            >
                {renderTree(areaTreeData)}
            </TreeView>

            <DeleteModal isOpen={openDeleteModal} onAccept={removeSelected} onClose={() => setOpenDeleteModal(false)} />
        </>
    );
};

export default AreaTree;
