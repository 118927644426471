import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#c6183e',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: [
            'Source Sans Pro', 
            'Arial',
            'sans-serif',
        ].join(','),
        allVariants: {
            fontFamily: 'inherit !important', // Override all variants with the specified font
        },
        '@font-face': [
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 900, // Black
                src: `
                    local('Source Sans Pro Black'),
                    local('SourceSansPro-Black'),
                    url('/src/fonts/SourceSansPro-Black.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 900, // Black Italic
                src: `
                    local('Source Sans Pro Black Italic'),
                    local('SourceSansPro-BlackItalic'),
                    url('/src/fonts/SourceSansPro-BlackItalic.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 700, // Bold
                src: `
                    local('Source Sans Pro Bold'),
                    local('SourceSansPro-Bold'),
                    url('/src/fonts/SourceSansPro-Bold.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 700, // Bold Italic
                src: `
                    local('Source Sans Pro Bold Italic'),
                    local('SourceSansPro-BoldItalic'),
                    url('/src/fonts/SourceSansPro-BoldItalic.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 200, // Extra Light
                src: `
                    local('Source Sans Pro Extra Light'),
                    local('SourceSansPro-ExtraLight'),
                    url('/src/fonts/SourceSansPro-ExtraLight.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 200, // Extra Light Italic
                src: `
                    local('Source Sans Pro Extra Light Italic'),
                    local('SourceSansPro-ExtraLightItalic'),
                    url('/src/fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 400, // Regular
                src: `
                    local('Source Sans Pro Regular'),
                    local('SourceSansPro-Regular'),
                    url('/src/fonts/SourceSansPro-Regular.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 400, // Italic
                src: `
                    local('Source Sans Pro Italic'),
                    local('SourceSansPro-Italic'),
                    url('/src/fonts/SourceSansPro-Italic.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 300, // Light
                src: `
                    local('Source Sans Pro Light'),
                    local('SourceSansPro-Light'),
                    url('/src/fonts/SourceSansPro-Light.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 300, // Light Italic
                src: `
                    local('Source Sans Pro Light Italic'),
                    local('SourceSansPro-LightItalic'),
                    url('/src/fonts/SourceSansPro-LightItalic.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 600, // SemiBold
                src: `
                    local('Source Sans Pro SemiBold'),
                    local('SourceSansPro-SemiBold'),
                    url('/src/fonts/SourceSansPro-SemiBold.ttf') format('truetype')
                `,
            },
            {
                fontFamily: 'Source Sans Pro',
                fontStyle: 'italic',
                fontWeight: 600, // SemiBold Italic
                src: `
                    local('Source Sans Pro SemiBold Italic'),
                    local('SourceSansPro-SemiBoldItalic'),
                    url('/src/fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype')
                `,
            },
        ],
    },
} as any);

export default theme;
