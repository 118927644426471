/* eslint-disable import/no-anonymous-default-export */

import BaseService from '../api/BaseService';

const url = 'https://martinusemailsenderrelay.azurewebsites.net/api/Function1?code=UHVClZ6nQHl6vS0s3m5AN04PKqGOeA7ouF3NQWuDkE-uAzFuEovHiA==';

export default {
    sendMail(eMail: string, subject: string, body: string, bcc: string = '') {
        return BaseService.sendMail(url, {
            Subject: subject,
            Email: eMail,
            Body: body,
            BCC: bcc,
        });
    },
};
