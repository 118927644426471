import React from 'react';
import { Grid, Typography } from '@mui/material';
import logo from '../../img/logo.png';
import HeaderCSS from './Header.module.css';

const Header = () => {
    return (
        <Grid container className={HeaderCSS.header}>
            <Grid item xs={4} container alignItems='center'>
                <Typography variant='h5' className={HeaderCSS.text} color="white">Pachtausschreibung</Typography>
            </Grid>
            <Grid item xs={4} container justifyContent='center' alignItems='center'>
                <img src={logo} alt='Logo' className={HeaderCSS.img} />
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
    );
};

export default Header;
