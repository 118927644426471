import { Navigate, Route, HashRouter as Router, Routes, useParams } from 'react-router-dom';
import DataMenu from '../menu/DataMenu';
import Startpage from '../startpage/Startpage';
import Config from '../../config/Config';
import ContentClosed from '../contentClosed/ContentClosed';
import ContentNotOpened from '../contentNotOpened/ContentNotOpened';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ToastMessage from '../helper/utils/toastMessage/ToastMessage';
import Maintenance from '../maintenance/Maintenance';
import BaseService from '../../api/BaseService';
import { Box, CircularProgress } from '@mui/material';
import InventoryService from '../../service/InventoryService';

const Content = () => {
    const [currentDateTime, setCurrentDateTime] = useState<Date>(null);

    const startDate = Config.getStartDate();
    const endDate = Config.getEndDate();

    const [activateMaintenance, setActivateMaintenance] = useState<boolean>(null);
    const [maintenanceTime, setMaintenanceTime] = useState<string>(null);

    useEffect(() => {
        const fetchTime = async () => {
            try {
                const response = await fetch('https://worldtimeapi.org/api/ip');
                if (response.ok) {
                    const data = await response.json();
                    const serverDateTime = new Date(data.datetime);

                    const austriaDateTime = new Date(serverDateTime.getTime() + 0 * 60 * 60 * 1000);

                    setCurrentDateTime(austriaDateTime);
                } else {
                    toast.error(ToastMessage.getServerErrorMessage());
                }
            } catch (error) {
                toast.error(ToastMessage.getServerErrorMessage());
            }
        };

        fetchTime();

        const intervalId = setInterval(fetchTime, 60000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        BaseService.getAuthorizationToken();

        const fetchMaintenanceStatus = async () => {
            try {
                const maintenance = await InventoryService.getAsset("1f61a3b0-0783-46db-9bec-8ecc1e2af4e9"); 

                const status = maintenance["Attributes"]["AKTIVIEREN"];
                const time = maintenance["Attributes"]["ENDZEIT_DER_WARTUNG__ALS_TEXT___ZB_15_00_UHR_"];

                setMaintenanceTime(time);
                setActivateMaintenance(status);
            } catch (error) {
                await BaseService.getAuthorizationToken();
            }
        };

        setTimeout(() => {
            fetchMaintenanceStatus();
        }, 1000);

        const intervalId = setInterval(fetchMaintenanceStatus, 30000);

        return () => clearInterval(intervalId);
    }, []);

    //SetUP mit Wartungarbeitenschalter
    // if (activateMaintenance === null)
    //     return (
    //         <Box display='flex' justifyContent='center' alignItems='center' height='100%' maxWidth='100%'>
    //             <CircularProgress color='primary' size={100} />
    //         </Box>
    //     );
    // if (activateMaintenance) return <Maintenance maintenanceTime={maintenanceTime} />;

    if (!currentDateTime) return <></>;

    if (currentDateTime < startDate) {
        return <ContentNotOpened />;
    } else if (currentDateTime > endDate) {
        return <ContentClosed />;
    } else {
        return (
            <Router>
                <Routes>
                    <Route path='/' element={<Startpage />} />
                    <Route path='/:id' element={<DataMenu />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
            </Router>
        );
    }
};

export default Content;
