import { Button, Modal, Box, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import ModalCSS from '../css/Modal.module.css';
import PDFModal from './PDFModal';
import GavelIcon from '@mui/icons-material/Gavel';
import ImportantInfosModal from './ImportantInfosModal';

type FinishModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
};

const FinishModal = ({ isOpen, onClose, onAccept }: FinishModalProps) => {
    const [accepted, setAccepted] = useState<boolean>(false);

    const [isPDFModalOpen, setIsPDFModalOpen] = useState<boolean>(false);
    const [isImportantInfosModalOpen, setIsImportantInfosModalOpen] = useState<boolean>(false);

    const handleAcceptClick = () => {
        if (accepted) {
            onAccept();
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={ModalCSS.modalBox}>
                <Grid container direction='column' spacing={1} className={ModalCSS.modalContent}>
                    <Grid item>
                        <Typography variant='h4' className={ModalCSS.modalTitle}>
                            Bestätigung
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            color='secondary'
                            onClick={() => setIsPDFModalOpen(true)}
                            startIcon={<GavelIcon />}
                            variant='outlined'
                            sx={{ mb: '4px' }}
                        >
                            Musterpachtvertrag zur Ansicht
                        </Button>
                        <br />
                        <Button color='secondary' onClick={() => setIsImportantInfosModalOpen(true)} startIcon={<GavelIcon />} variant='outlined'>
                            Wichtige Hinweise zur Verpachtung und zum Pachtvertrag
                        </Button>
                    </Grid>
                    <Grid item>
                        <label htmlFor='agbCheckbox' className={ModalCSS.checkboxLabel}>
                            <input
                                type='checkbox'
                                id='agbCheckbox'
                                checked={accepted}
                                onChange={(e) => setAccepted(e.target.checked)}
                                className={ModalCSS.checkboxInput}
                            />
                            <span>Ich bestätige hiermit, dass ich Obengenanntes zur Kenntnis nehme und meine Angaben korrekt sind.</span>
                        </label>
                    </Grid>
                    <Grid item className={ModalCSS.buttonsContainer}>
                        <Button variant='outlined' color='success' onClick={handleAcceptClick} disabled={!accepted}>
                            Absenden
                        </Button>
                        <Button variant='outlined' color='error' onClick={onClose} sx={{ ml: 2 }}>
                            Ablehnen
                        </Button>
                    </Grid>
                </Grid>

                <PDFModal src={'/pdfs/Musterpachtvertrag.pdf'} isOpen={isPDFModalOpen} onClose={() => setIsPDFModalOpen(false)} />
                <ImportantInfosModal isOpen={isImportantInfosModalOpen} onClose={() => setIsImportantInfosModalOpen(false)} />
            </Box>
        </Modal>
    );
};

export default FinishModal;
