/* eslint-disable react-hooks/exhaustive-deps */
import { InputLabel } from '@mui/material';
import { useState, useEffect } from 'react';
import Select from 'react-select';

export type SelectInputType = {
    label: string;
    value: string;
};

type DynamicSelectProps = {
    label: string;
    data: SelectInputType[];
    setValue: (newValue: string) => void;
};

//Why does this not work right on some devices <- maybe disabled JS or some Browser blocking???

const DynamicSelect = ({ label, data, setValue }: DynamicSelectProps) => {
    const [inputValue, setInputValue] = useState<string>('');
    // const [options, setOptions] = useState<SelectInputType[]>([]);
    // const [shownOptionsLength, setShownOptionsLength] = useState<number>(20);

    // useEffect(() => {
    //     if (inputValue === '') {
    //         setOptions(data.slice(0, shownOptionsLength));
    //     } else {
    //         setOptions(data.filter((d) => d.label.toLocaleLowerCase().includes(inputValue)).slice(0, shownOptionsLength));
    //     }
    // }, [inputValue, shownOptionsLength, data]);

    // useEffect(() => {
    //     setShownOptionsLength(20);
    // }, [inputValue]);

    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
        }
    };

    // const showMoreData = () => {
    //     setShownOptionsLength((prev) => prev + 20);
    // };

    const onChangeHandler = (newValue) => {
        setInputValue('');
        setValue(newValue?.value);
    };

    return (
        <div>
            <InputLabel sx={{ marginLeft: '5px' }}>{label}</InputLabel>
            <Select
                placeholder="Auswählen..."
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={onChangeHandler}
                options={data}
                isClearable
                isSearchable
                // onMenuScrollToBottom={showMoreData}
            />
        </div>
    );
};

export default DynamicSelect;
