import { Modal, Box, IconButton } from '@mui/material';
import PDFModalCSS from '../css/PDFModal.module.css';
import CloseIcon from '@mui/icons-material/Close';

type PDFModalProps = {
    src: string;

    isOpen: boolean;
    onClose: () => void;
};

const PDFModal = ({ src, isOpen, onClose }: PDFModalProps) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={PDFModalCSS.modalBox}>
                <Box className={PDFModalCSS.closeBox}>
                    <IconButton onClick={onClose} className={PDFModalCSS.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <embed className={PDFModalCSS.pdf} src={src} />
            </Box>
        </Modal>
    );
};

export default PDFModal;
