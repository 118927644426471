function toString(promise: Promise<Response>): Promise<string> {
    return promise.then((x) => x.text());
}

function toNumber(promise: Promise<Response>): Promise<number> {
    return toString(promise).then((x) => Number(x));
}

function toJson<T>(promise: Promise<Response>): Promise<T> {
    return promise.then((res) => {
        return res.text().then((text) => {
            try {
                return JSON.parse(text);
            } catch (error) {}
        });
    });
}

export default {
    toString,
    toNumber,
    toJson,
};
