import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { UserData } from '../../types/types';
import MailService from '../../service/MailService';
import HelperEMail from '../helper/utils/helperFunctions/HelperEMail';
import ReactivateUserModal from '../helper/modal/tsx/ReactivateUserModal';
import DataFinishedCSS from './DataFinished.module.css';
import { toast } from 'react-toastify';
import InventoryService from '../../service/InventoryService';
import HelperFunctions from '../helper/utils/helperFunctions/HelperFunctions';
import ToastMessage from '../helper/utils/toastMessage/ToastMessage';
import Config from '../../config/Config';

type DataFinishedProps = {
    userData: UserData;
    setUserData: (newUserData: UserData) => void;
};

const DataFinished = ({ userData, setUserData }: DataFinishedProps) => {
    const [reactivateUserModalOpen, setReactivateUserModalOpen] = useState<boolean>(false);

    const reactivateUser = async () => {
        setUserData({
            ...userData,
            status: 'In Bearbeitung',
        });

        InventoryService.searchUser(userData.eMail).then(async (res) => {
            if (res['QueryObjects'].length !== 0) {
                MailService.sendMail(
                    userData.eMail,
                    'Link zum Pachtausschreibungsportal der Diözese Eisenstadt',
                    (await HelperEMail.getRecoveryEMail()).replace('{ph1}', HelperFunctions.getAccountLink(res['QueryObjects'][0]['id'])),
                    Config.getBccMailDataRestored()
                )
                    .then(() => {
                        toast.success(ToastMessage.getEMailSentSuccessfully());
                    })
                    .catch((error) => {
                        toast.error(ToastMessage.getEMailSentError());
                    });
            } else {
                toast.error(ToastMessage.getServerErrorMessage());
            }
        });
    };

    return (
        <>
            <div className={DataFinishedCSS.container}>
                <div className={DataFinishedCSS.top}>
                    <Typography variant='h4'>Abschlussbericht</Typography>
                </div>
                <div className={DataFinishedCSS.middle}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                Vielen Dank für Ihr Angebot. Eine Zusammenfassung Ihrer Daten wurde an Ihre E-Mail-Adresse versandt.
                            </Typography>
                            <Typography variant='body1'>
                                Nach Beendigung der Angebotsfrist mit {HelperFunctions.formatDateWithLeadingZero(Config.getEndDate(), true)} 24:00 wird mit
                                der Auswertung aller eingelangten Angebote begonnen. Sie werden voraussichtlich bis{' '}
                                {HelperFunctions.formatDateWithLeadingZero(Config.getEndOfEvaluationDate())} über das Ergebnis informiert.
                            </Typography>
                            <Typography variant='body1'>
                                Die Liegenschaftsabteilung der wirtschaftlichen Generaldirektion der Diözese Eisenstadt.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                Falls Sie Ihr Angebot stornieren möchten, haben Sie die Möglichkeit dies mit der untenstehenden Schaltfläche
                                vorzunehmen.
                            </Typography>
                            <Typography variant='body1'>Nach Betätigung erhalten Sie per E-Mail einen Link mit Ihrem erneuten Zugang.</Typography>
                            <Typography variant='body1'>Danach können Sie Ihre Daten ändern und Ihr verändertes Angebot neu abgeben.</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={DataFinishedCSS.bottom}>
                    <Button color='secondary' variant='outlined' onClick={() => setReactivateUserModalOpen(true)}>
                        Angebot stornieren
                    </Button>
                </div>
            </div>

            <ReactivateUserModal isOpen={reactivateUserModalOpen} onClose={() => setReactivateUserModalOpen(false)} onAccept={reactivateUser} />
        </>
    );
};

export default DataFinished;
