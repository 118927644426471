/* eslint-disable import/no-anonymous-default-export */
import { AssetRelation, AssetRelationConfiguration, LeaseData, LeasedAreaData, UserData } from '../../../../types/types';
import { Asset, AssetWithRelations } from '../../../../types/types';

const assetDbId = 'bcb4345c-f870-4041-952c-52fbedb294a9';

export default {
    createUser(eMail: string): AssetWithRelations {
        return {
            Asset: {
                TypeName: 'PAECHTER',
                ObjectClassName: null,
                Attributes: {
                    VGTITEL: null,
                    NGTITEL: null,
                    VORNAME: null,
                    NACHNAME: null,
                    GEBURTSDATUM: null,
                    STRASSE: null,
                    HAUSNUMMER: null,
                    POSTLEIZAHL: null,
                    ORT: null,
                    E_MAIL: eMail,
                    TELEFONNUMMER: null,
                    BETRIEBSART: null,
                    BETRIEBSNUMMER: null,
                    ANMERKUNG: null,
                    STATUS: 'In Bearbeitung',
                },
                AssetId: null,
                AssetDbId: assetDbId,
            },
            CurrentRelations: [],
            CurrentSubAssets: [],
            RelationsToAdd: [],
            RelationsToDelete: [],
            SubAssetsToAdd: [],
            SubAssetsToDelete: [],
            SubAssetsToEdit: [],
            AssetIDsToDelete: [],
            AssetIDsToEdit: [],
        } as AssetWithRelations;
    },
    userAsset(assetId: string, userData: UserData): Asset {
        return {
            TypeName: 'PAECHTER',
            ObjectClassName: null,
            Attributes: {
                VGTITEL: userData.featuredTitle,
                NGTITEL: userData.acatechTitle,
                VORNAME: userData.firstname,
                NACHNAME: userData.lastname,
                GEBURTSDATUM: userData.birthdate,
                STRASSE: userData.street,
                HAUSNUMMER: userData.streetNumber,
                POSTLEIZAHL: userData.postCode,
                ORT: userData.community,
                E_MAIL: userData.eMail,
                TELEFONNUMMER: userData.phoneNumber,
                BETRIEBSART: userData.bioOrConventional,
                KUNDENNUMMER_DER_KONTROLLSTELLE: userData.bioId,
                BETRIEBSNUMMER: userData.companyNumber,
                ANMERKUNG: userData.note,
                STATUS: userData.status,
            },
            AssetId: assetId,
            AssetDbId: assetDbId,
        } as Asset;
    },
    createLeaseData(leaseData: LeaseData): AssetWithRelations {
        return {
            Asset: {
                TypeName: 'ANGEBOT',
                ObjectClassName: null,
                Attributes: {
                    ANGEBOT_IN___HA: leaseData.offer,
                    ANMERKUNG: leaseData.note,
                },
                AssetId: null,
                AssetDbId: assetDbId,
            },
            CurrentRelations: [],
            CurrentSubAssets: [],
            RelationsToAdd: [],
            RelationsToDelete: [],
            SubAssetsToAdd: [],
            SubAssetsToDelete: [],
            SubAssetsToEdit: [],
            AssetIDsToDelete: [],
            AssetIDsToEdit: [],
        } as AssetWithRelations;
    },
    leaseDataAssetWithNewRelation(leaseData: LeaseData, userDataId: string, leasedAreaDataId: string): AssetWithRelations {
        return {
            Asset: {
                TypeName: 'ANGEBOT',
                ObjectClassName: null,
                Attributes: {
                    ANGEBOT_IN___HA: leaseData.offer,
                    ANMERKUNG: leaseData.note,
                },
                AssetId: leaseData.id,
                AssetDbId: assetDbId,
            },
            CurrentRelations: [],
            CurrentSubAssets: [],
            RelationsToAdd: [
                {
                    AssetId1: leasedAreaDataId,
                    AssetId2: leaseData.id,
                    Configuration: {
                        RelationInternalName: 'REL_PACHTFLAECHE_ANGEBOT',
                        ObjectTypeInternalName1: 'PACHTFLAECHE',
                        ObjectTypeInternalName2: 'ANGEBOT',
                        IsContentOrEndpointRelation: false,
                    } as AssetRelationConfiguration,
                } as AssetRelation,
                {
                    AssetId1: userDataId,
                    AssetId2: leaseData.id,
                    Configuration: {
                        RelationInternalName: 'REL_PAECHTER_ANGEBOT',
                        ObjectTypeInternalName1: 'PAECHTER',
                        ObjectTypeInternalName2: 'ANGEBOT',
                        IsContentOrEndpointRelation: false,
                    } as AssetRelationConfiguration,
                } as AssetRelation,
            ],
            RelationsToDelete: [],
            SubAssetsToAdd: [],
            SubAssetsToDelete: [],
            SubAssetsToEdit: [],
            AssetIDsToDelete: [],
            AssetIDsToEdit: [],
        } as AssetWithRelations;
    },
    leaseDataAsset(leaseData: LeaseData): AssetWithRelations {
        return {
            Asset: {
                TypeName: 'ANGEBOT',
                ObjectClassName: null,
                Attributes: {
                    ANGEBOT_IN___HA: leaseData.offer,
                    ANMERKUNG: leaseData.note,
                },
                AssetId: leaseData.id,
                AssetDbId: assetDbId,
            },
            CurrentRelations: [],
            CurrentSubAssets: [],
            RelationsToAdd: [],
            RelationsToDelete: [],
            SubAssetsToAdd: [],
            SubAssetsToDelete: [],
            SubAssetsToEdit: [],
            AssetIDsToDelete: [],
            AssetIDsToEdit: [],
        } as AssetWithRelations;
    },
    getAccountLink(assetId: string): string {
        const url = "https://verpachtung.martinus.at/"; //prod
        // const url = "http://localhost:3000/"; //local

        return `<a href="${url}#/${assetId}">${url}#/${assetId}</a>`;
    },
    formatDateWithLeadingZero(date: Date, minusOneDay: boolean = false): string {
        return String(minusOneDay ? date.getDate() - 1 : date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + String(date.getFullYear());
    },
    formatNumberWithThousandSeparator(number: number | string): string {
        const numberString = number.toString();

        if (numberString.length <= 3) {
            return numberString;
        }

        const lastThreeDigits = numberString.slice(-3);
        const remainingDigits = numberString.slice(0, -3);
        const formattedNumber = remainingDigits + '.' + lastThreeDigits;

        return formattedNumber;
    },
    calculateActualPayment(row: LeasedAreaData, leaseData: LeaseData[]): number {
        return Number(((row.area / 10000) * leaseData.find((ld) => ld.leasedAreaDataId === row.id)?.offer).toFixed(2));
    },
    formatNumberWithThousandSeparatorAndComma(number: number): string {
        return number.toLocaleString('at', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
};
