/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { LeasedAreaData, LeaseData, PropertyData, StepType, UserData } from '../../types/types';
import { Box, Button, CircularProgress, Step, StepButton, Stepper, Typography } from '@mui/material';
import DataPage from './stepPages/DataPage';
import MapPage from './stepPages/MapPage';
import SummaryPage from './stepPages/SummaryPage';
import DataMenuCSS from './DataMenu.module.css';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FinishModal from '../helper/modal/tsx/FinishModal';
import InventoryService from '../../service/InventoryService';
import BaseService from '../../api/BaseService';
import DataConverter from '../helper/utils/dataconverter/DataConverter';
import HelperFunctions from '../helper/utils/helperFunctions/HelperFunctions';
import useStateRef from 'react-usestateref';
import ToastMessage from '../helper/utils/toastMessage/ToastMessage';
import DataFinished from '../dataFinished/DataFinished';
import MailService from '../../service/MailService';
import HelperEMail from '../helper/utils/helperFunctions/HelperEMail';
import Config from '../../config/Config';
import { LoadingButton } from '@mui/lab';
import debounce from 'lodash/debounce';

const steps: StepType[] = [
    {
        index: 0,
        name: 'Daten',
        component: DataPage,
    },
    {
        index: 1,
        name: 'Karte',
        component: MapPage,
    },
    {
        index: 3,
        name: 'Zusammenfassung',
        component: SummaryPage,
    },
];

const DataMenu = () => {
    const { id } = useParams();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [completedSteps, setCompletedSteps] = useState<Map<number, boolean>>(new Map());

    const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([]);

    const [tablePropertyData, setTablePropertyData] = useState<PropertyData[]>([]);
    const [tableLeasedAreaData, setTableLeasedAreaData, tableLeasedAreaDataRef] = useStateRef<LeasedAreaData[]>([]);

    const [leaseData, setLeaseData, leaseDataRef] = useStateRef<LeaseData[]>([]);

    const [userData, setUserData, userDataRef] = useStateRef<UserData>(null);

    const [isUserDataSaving, setIsUserDataSaving] = useState<boolean>(false);
    const [isLeaseDataSaving, setIsLeaseDataSaving] = useState<boolean>(false);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [showDataMissingIcon, setShowDataMissingIcon] = useState<boolean>(false);
    const [showErrorInTable, setShowErrorInTable] = useState<boolean>(true);

    const [currentUserStatus, setCurrentUserStatus] = useState<string>('');

    const [qualityCheckRunning, setQualityCheckRunning] = useState<boolean>(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                await BaseService.getAuthorizationToken();

                const userDataRes = await InventoryService.getAsset(id);
                const currentUserData = DataConverter.convertUserDataToEnglish(userDataRes['Attributes']);

                setUserData(currentUserData);
                setCurrentUserStatus(currentUserData.status);

                const leaseDataRes = await InventoryService.getAllRelatedAssets(id, 'REL_PAECHTER_ANGEBOT');

                await Promise.all(
                    leaseDataRes.map(async (ldr) => {
                        const leasedAreaDataRes = await InventoryService.getAllRelatedAssets(ldr['AssetId'], 'REL_PACHTFLAECHE_ANGEBOT');

                        let newTablePropertyData: PropertyData[] = await Promise.all(
                            leasedAreaDataRes.map(async (lad) => {
                                const propertyDataRes = await InventoryService.getAllRelatedAssets(lad['AssetId'], 'REL_PACHTFLAECHE_LIEGENSCHAFT');
                                return DataConverter.convertPropertyDataToEnglishV2(propertyDataRes);
                            })
                        );

                        newTablePropertyData = newTablePropertyData.flat();

                        setTablePropertyData((prev) => [...prev, ...newTablePropertyData]);

                        setTableLeasedAreaData((prev) => [
                            ...prev,
                            ...DataConverter.convertLeasedAreaDataEnglish(
                                newTablePropertyData.flatMap((ntpd) => ntpd.id),
                                leasedAreaDataRes
                            ).map((tlad) => {
                                tlad.hasError =
                                    userDataRef.current.bioOrConventional === 'Konventionell' && (tlad.bio === 'Bio' || tlad.bio === 'Teilweise Bio');

                                return tlad;
                            }),
                        ]);

                        setSelectedAreaIds((prev) => [...prev, leasedAreaDataRes[0]?.['AssetId']]);
                        setLeaseData((prev) => [...prev, DataConverter.convertLeaseDataToEnglish(leasedAreaDataRes[0]?.['AssetId'], ldr)]);
                    })
                );

                setTimeout(() => {
                    setInitialized(true);
                }, 1000);
            } catch (error) {
                toast.error(ToastMessage.getServerErrorMessage());
            }
        };

        fetchUserData();
    }, [id]);

    useEffect(() => {
        let userStatusInterval;

        setTimeout(() => {
            userStatusInterval = setInterval(async () => {
                try {
                    const userDataRes = await InventoryService.getAsset(id);

                    const newUserStatus = DataConverter.convertUserDataToEnglish(userDataRes['Attributes']).status;
                    if (currentUserStatus !== newUserStatus) {
                        setCurrentUserStatus(newUserStatus);
                    }
                } catch (error) {
                    await BaseService.getAuthorizationToken();
                }
            }, 5000);
        }, 10000);

        return () => clearInterval(userStatusInterval);
    }, []);

    //ErrorInTableChecker
    useEffect(() => {
        setShowErrorInTable(
            tableLeasedAreaData.filter((tlad) => tlad.hasError).length !== 0 ||
                !(leaseData.filter((ld) => ld.offer === 0 || ld.offer === null).length === 0)
        );
    }, [tableLeasedAreaData, leaseData]);

    //LeaseDataBuilder
    useEffect(() => {
        if (!initialized) return;

        const fetchData = async () => {
            let newTablePropertyData = [...tablePropertyData];
            let newTableLeasedAreaData = [...tableLeasedAreaData];
            let newLeaseData = [...leaseData];

            if (selectedAreaIds.every((sai) => leaseData.find((ld) => ld.leasedAreaDataId === sai))) {
                newTableLeasedAreaData = newTableLeasedAreaData.filter((tlad) => selectedAreaIds.includes(tlad.id));
                newTablePropertyData = newTablePropertyData.filter((tpd) =>
                    newTableLeasedAreaData.some((ntlad) => ntlad.propertyDataIds.includes(tpd.id))
                );

                const leaseDataToDelete = newLeaseData.filter((ld) => !selectedAreaIds.includes(ld.leasedAreaDataId));

                leaseDataToDelete.forEach((ldtd) => {
                    InventoryService.deleteAsset(ldtd.id).catch((error) => {
                        toast.error(ToastMessage.getServerErrorMessage());
                    });
                });

                newLeaseData = newLeaseData.filter((ld) => selectedAreaIds.includes(ld.leasedAreaDataId));
            } else {
                const toBeAddedLeasedAreaIds = selectedAreaIds.filter((sai) => !leaseData.find((ld) => ld.leasedAreaDataId === sai));

                for (const lai of toBeAddedLeasedAreaIds) {
                    const res = await InventoryService.getAsset(lai);
                    const res2 = await InventoryService.getAllRelatedAssets(lai, 'REL_PACHTFLAECHE_LIEGENSCHAFT');

                    const newPropertyData = DataConverter.convertPropertyDataToEnglishV2(res2);
                    const newLeasedAreaData = DataConverter.convertLeasedAreaDataEnglish(
                        newPropertyData.map((npd) => npd.id),
                        [res]
                    )[0];

                    const newLeaseDataEntry = {
                        id: '',
                        leasedAreaDataId: newLeasedAreaData.id,
                        offer: null,
                        note: '',
                    } as LeaseData;

                    newTablePropertyData.push(...newPropertyData);
                    newTableLeasedAreaData.push(newLeasedAreaData);
                    newLeaseData.push(newLeaseDataEntry);
                }
            }

            newTableLeasedAreaData = newTableLeasedAreaData.map((tlad) => {
                tlad.hasError = userDataRef.current.bioOrConventional === 'Konventionell' && (tlad.bio === 'Bio' || tlad.bio === 'Teilweise Bio');

                return tlad;
            });

            setTablePropertyData(newTablePropertyData);
            setLeaseData(newLeaseData);
            setTableLeasedAreaData(newTableLeasedAreaData);
        };

        fetchData();
    }, [selectedAreaIds, userData?.bioOrConventional]);

    useEffect(() => {
        if (!initialized) return;

        const debouncedSave = debounce(() => saveUserData(), 5000);

        debouncedSave();
        setIsUserDataSaving(true);

        return () => {
            debouncedSave.cancel();
        };
    }, [userData]);

    const saveUserData = () => {
        const userAsset = HelperFunctions.userAsset(id, userDataRef.current);

        InventoryService.updateAsset(userAsset)
            .then(() => {
                setIsUserDataSaving(false);
                toast.success(ToastMessage.getUserDataSavedMessage());
            })
            .catch((error) => {
                toast.error(ToastMessage.getDataNotSavedMessage());
                setIsUserDataSaving(false);
            });
    };

    useEffect(() => {
        if (!initialized) return;

        const debouncedSave = debounce(() => saveLeaseData(), 5000);

        debouncedSave();
        setIsLeaseDataSaving(true);

        return () => {
            debouncedSave.cancel();
        };
    }, [leaseData]);

    const saveLeaseData = async () => {
        try {
            const promises = leaseDataRef.current.map(async (ld) => {
                if (ld.id === '') {
                    const res = await InventoryService.createAssetWithRelation(HelperFunctions.createLeaseData(ld));
                    ld.id = res['AssetId'];

                    await InventoryService.updateAssetWithRelation(
                        HelperFunctions.leaseDataAssetWithNewRelation(
                            ld,
                            id,
                            tableLeasedAreaDataRef.current.find((tladr) => tladr.id === ld.leasedAreaDataId).id
                        )
                    );
                } else {
                    await InventoryService.updateAssetWithRelation(HelperFunctions.leaseDataAsset(ld));
                }
            });

            await Promise.all(promises);

            toast.success(ToastMessage.getLeaseDataSavedMessage());
            setIsLeaseDataSaving(false);
        } catch (error) {
            toast.error(ToastMessage.getDataNotSavedMessage());
            setIsLeaseDataSaving(false);
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isUserDataSaving || isLeaseDataSaving) {
                const message = 'Es wird gerade gespeichert, wollen sie wirklich beenden?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isUserDataSaving, isLeaseDataSaving]);

    const onFinisheDataAccepted = async () => {
        MailService.sendMail(
            userData.eMail,
            'Abschlussbericht',
            await HelperEMail.getFinishedEMail(tablePropertyData, tableLeasedAreaData, leaseData, userData),
            Config.getBccMailDataFinished()
        )
            .then(() => {
                toast.success(ToastMessage.getEMailSentSuccessfully());
            })
            .catch((error) => {
                toast.success(ToastMessage.getEMailSentError());
            });

        setUserData({
            ...userData,
            status: 'Abgeschlossen',
        });

        setActiveStep(0);
        setCompletedSteps(new Map());
        setOpenModal(false);
    };

    function hasDuplicates(array: any[]): boolean {
        return array.filter((value, index) => array.indexOf(value) !== index).length > 0;
    }

    const finishData = async () => {
        setQualityCheckRunning(true);

        let hasDuplicateError = false;

        if (leaseData.filter((ld) => ld.offer === 0 || ld.offer === null).length === 0 && leaseData.length !== 0 && !showErrorInTable) {
            try {
                const ldResultList = await InventoryService.getAllRelatedAssets(id, 'REL_PAECHTER_ANGEBOT');

                await Promise.all(
                    ldResultList.map(async (ldResult) => {
                        const ld = DataConverter.convertLeaseDataToEnglish('', ldResult);

                        const ladResultList = await InventoryService.getAllRelatedAssets(ld.id, 'REL_PACHTFLAECHE_ANGEBOT');
                        const ladList = DataConverter.convertLeasedAreaDataEnglish([], ladResultList);

                        await Promise.all(
                            ladList.map(async (lad) => {
                                if (!hasDuplicateError) {
                                    const ldResultListFromLad = await InventoryService.getAllRelatedAssets(lad.id, 'REL_PACHTFLAECHE_ANGEBOT');

                                    if (ldResultListFromLad.length > 1) {
                                        const userList = ldResultListFromLad.map((leaseData) => leaseData['Attributes']['PAECHTER']);

                                        if (hasDuplicates(userList)) {
                                            await MailService.sendMail('kemmer@rmdatagroup.com', 'Fehler bei Pächter!', id);

                                            hasDuplicateError = true;
                                        }
                                    }
                                }
                            })
                        );
                    })
                );

                if (!hasDuplicateError) {
                    setOpenModal(true);
                    setQualityCheckRunning(false);
                } else {
                    setQualityCheckRunning(false);
                    toast.error(ToastMessage.getNotAllOfferSetError());
                }
            } catch (error) {
                await BaseService.getAuthorizationToken();
            }
        } else {
            setQualityCheckRunning(false);
            toast.error(ToastMessage.getNotAllOfferSetError());
        }
    };

    const nextStep = () => {
        if (!allUserDataEntered()) {
            toast.error(ToastMessage.getMissigeAttributesErrorMessage());
            setShowDataMissingIcon(true);

            return;
        }

        const newMap = new Map(completedSteps);
        newMap.set(activeStep, true);
        setCompletedSteps(newMap);

        setShowDataMissingIcon(false);

        if (activeStep + 1 !== steps.length) setActiveStep((prevStep) => prevStep + 1);
    };

    const previousStep = () => {
        if (activeStep > 0) {
            setActiveStep((prevStep) => prevStep - 1);

            const newMap = new Map(completedSteps);
            newMap.delete(activeStep - 1);
            setCompletedSteps(newMap);
        }
    };

    const allStepsCompleted = () => {
        return steps.length - 1 === activeStep;
    };

    const allUserDataEntered = () => {
        if (userData.bioOrConventional === 'Bio' && (userData.bioId === null || userData.bioId === '')) {
            return false;
        }

        for (const key in userData) {
            if (key !== 'bioId' && key !== 'note') {
                if (userData.hasOwnProperty(key)) {
                    if (key !== 'featuredTitle' && key !== 'acatechTitle') {
                        if (key === 'birthdate') {
                            if (!isNaN(userData[key]?.['$D']) && !isNaN(userData[key]?.['$M']) && !isNaN(userData[key]?.['$y'])) {
                                continue;
                            } else {
                                return false;
                            }
                        } else {
                            const value = userData[key];
                            if (value === null || value === '') {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    };

    const ActiveComponent = steps[activeStep]?.component;

    if (!initialized || userData.status !== currentUserStatus) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%' maxWidth='100%'>
                <CircularProgress color='primary' size={100} />
            </Box>
        );
    }

    if (currentUserStatus === 'Abgeschlossen') {
        return <DataFinished userData={userData} setUserData={setUserData} />;
    }

    return (
        <div className={DataMenuCSS.flexContainer}>
            <div className={DataMenuCSS.stepperRow}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((step) => (
                        <Step key={step.name} completed={completedSteps.get(step.index)}>
                            <StepButton color='primary'>{step.name}</StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div className={DataMenuCSS.pageRow}>
                {ActiveComponent && (
                    <ActiveComponent
                        userData={userData}
                        setUserData={setUserData}
                        leaseData={leaseData}
                        setLeaseData={setLeaseData}
                        selectedAreaIds={selectedAreaIds}
                        setSelectedAreaIds={setSelectedAreaIds}
                        tablePropertyData={tablePropertyData}
                        tableLeasedAreaData={tableLeasedAreaData}
                        showDataMissingIcon={showDataMissingIcon}
                        showErrorInTable={showErrorInTable}
                    />
                )}
            </div>

            <div className={DataMenuCSS.bottomRow}>
                <div className={DataMenuCSS.leftBlock}>
                    <Typography variant='body1'>Ihre eingegebenen Daten werden im Hintergrund automatisch gespeichert.</Typography>
                </div>
                <div className={DataMenuCSS.rightBlock}>
                    {(isUserDataSaving || isLeaseDataSaving) && (
                        <>
                            <Typography variant='body1' style={{ marginRight: '10px' }}>
                                Speichervorgang
                            </Typography>
                            <CircularProgress size={25} style={{ marginRight: '10px' }} />
                        </>
                    )}

                    <Button color='secondary' variant='outlined' disabled={activeStep === 0} onClick={previousStep} style={{ marginRight: '10px' }}>
                        Zurück
                    </Button>

                    {!allStepsCompleted() ? (
                        <Button color='secondary' variant='outlined' onClick={nextStep} style={{ marginRight: '10px' }}>
                            Weiter
                        </Button>
                    ) : (
                        <LoadingButton
                            loading={qualityCheckRunning}
                            disabled={isUserDataSaving || isLeaseDataSaving}
                            color='success'
                            variant='outlined'
                            onClick={finishData}
                            style={{ marginRight: '10px' }}
                        >
                            Angebot verbindlich absenden
                        </LoadingButton>
                    )}
                </div>
            </div>

            <FinishModal isOpen={openModal} onAccept={onFinisheDataAccepted} onClose={() => setOpenModal(false)} />
        </div>
    );
};

export default DataMenu;
