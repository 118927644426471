import { Grid, Link, Typography } from '@mui/material';
import FooterCSS from './Footer.module.css';

const Footer = () => {
    return (
        <Grid container columnSpacing={4} className={FooterCSS.footer}>
            <Grid container xs={4} className={FooterCSS.row}>
                <div className={FooterCSS.verticalBox}>
                    <Typography color='white'>DIÖZESE EISENSTADT</Typography>
                    <Typography color='white'>St. Rochus-Straße 21</Typography>
                    <Typography color='white'>7000 Eisenstadt</Typography>
                </div>
            </Grid>

            <Grid container xs={4} className={FooterCSS.row}>
                <div className={FooterCSS.verticalBox}>
                    <Typography color='white'>Tel.: 02682 777 - 366</Typography>
                    <Link href='mailto:liegenschaft@martinus.at' color='white' underline='none' target='_blank'>
                        E-Mail: liegenschaft@martinus.at
                    </Link>
                </div>
            </Grid>

            <Grid container xs={4} className={FooterCSS.row}>
                <div className={FooterCSS.verticalBox}>
                    <Link href='https://www.martinus.at/portal/impressum' color='white' underline='none' target='_blank'>
                        Impressum
                    </Link>
                    <Link href='https://www.martinus.at/portal/datenschutz' color='white' underline='none' target='_blank'>
                        Datenschutz
                    </Link>
                </div>
            </Grid>
        </Grid>
    );
};

export default Footer;
